<template>
  <section class="threed">
    <div class="threed__image">
      <div :class="['threed__image-image', `threed__image-image--${bg}`]"></div>
      <!-- ['gallery__content', `gallery__content--${ slide.bg }`]" -->
    </div>
    <div class="threed__info">
      <h3 class="threed__heading heading heading--3">3D/360º walkthroughs</h3>
      <p>
        Om een betere indruk te krijgen van onze aanbod kunt u een virtuele 3D tour doen. Bekijk de zaal in 360º of van
        bovenaf als plategrond.
      </p>
      <div v-if="url">
        <div class="threed__icons">
          <div class="threed__360"></div>
          <div class="threed__cardboard"></div>
        </div>
        <div>
          <a class="threed__link" target="_blank" :href="url">bekijk zaal</a>
        </div>
        <br />
        <div>
          <a v-if="combiUrl" class="threed__link" target="_blank" :href="combiUrl">bekijk combinatiezaal</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Threed",

  props: ["heading", "info", "url", "combiUrl", "bg"]
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.threed {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto 40px;
  padding: 0 16px;
  max-width: $inner-max-width;

  @include respond-to("m") {
    flex-direction: row;
    margin: 0 auto 120px;
    padding: 0;
  }
}

.threed__image {
  flex: 1;
  margin-top: 40px;
  border-right: 8px solid $red;

  &-image {
    padding-top: 56.25%;
    background-image: url("../assets/images/threed-vergaderzaal.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;

    $rooms: ("theater", "forum", "hall-1", "hall-2", "hall-3", "hall-4", "hall-5", "hall-6");
    @each $room in $rooms {
      &--#{$room} {
        background-image: url("../assets/images/threed-#{$room}.png");
      }
    }
  }
}

.threed__info {
  flex: 1;
  padding: 0 16px;
}

.threed__icons {
  display: flex;
  margin: 24px 0;
}

.threed__360 {
  width: 62px;
  height: 40px;
  background-image: url("../assets/images/threed-360.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.threed__cardboard {
  width: 40px;
  height: 26px;
  margin-left: 24px;
  background-image: url("../assets/images/threed-cardboard.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.threed__link {
  color: $red;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 10px;
    margin: 3px 0 0 12px;
    background-image: url("../assets/images/red-arrow-right.svg");
    background-size: cover;
    transition: transform $animated;
  }
}

@include respond-to("m") {
  .threed__image {
    margin-top: 0;
    padding-left: 16px;
  }

  .threed__info {
    p {
      max-width: 368px;
    }
  }
}
</style>
