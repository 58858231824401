<template>
  <section class="footer__capsule" data-aos="fade-up">
    <footer class="footer" data-aos="fade-up">
      <div class="footer__wrapper">
        <div class="footer__section footer__section--logo">
          <div class="footer__logo">
            <Logo type="large" />
          </div>
          <p class="footer__paragraph">OBA Congres is onderdeel van de OBA (Openbare Bibliotheek Amsterdam).</p>
          <div>
            <a class="footer__link" target="_blank" href="https://www.oba.nl/privacy.html">privacy statement</a> |
            <a class="footer__link" target="_blank" href="https://www.oba.nl/disclaimer.html">disclaimer</a>
          </div>
        </div>
      </div>
      <div class="footer__wrapper">
        <div class="footer__container">
          <div>
            <h4 class="footer__title">
              <router-link :to="{ name: 'OnzeRuimtes' }">de ruimtes</router-link>
            </h4>
            <div class="footer__section">
              <router-link :to="{ name: 'VergaderzalenOverview' }" class="footer__link">vergaderzalen</router-link>
              <router-link :to="{ name: 'Forum' }" class="footer__link">OBA Forum</router-link>
              <router-link :to="{ name: 'Theater' }" class="footer__link">OBA Theater</router-link>
              <router-link :to="{ name: 'PrivateDining' }" class="footer__link">private dining</router-link>
            </div>
          </div>
          <div>
            <h4 class="footer__title">
              <router-link :to="{ name: 'UwEvent' }">uw event</router-link>
            </h4>
            <div class="footer__section">
              <router-link :to="{ name: 'Vergaderen' }" class="footer__link">vergaderen</router-link>
              <router-link :to="{ name: 'CongresBeurs' }" class="footer__link">congres & beurs</router-link>
              <router-link :to="{ name: 'Presenteren' }" class="footer__link">presenteren</router-link>
              <router-link :to="{ name: 'EtenDrinken' }" class="footer__link">eten & drinken</router-link>
              <router-link :to="{ name: 'Activiteiten' }" class="footer__link">activiteiten</router-link>
            </div>
          </div>
        </div>
        <h4 class="footer__title footer__title--mobile">
          <router-link :to="{ name: 'Contact' }">contact</router-link>
        </h4>
        <div class="footer__section footer__section--contact">
          <div class="footer__container">
            <h4 class="footer__title footer__title--desktop">
              <router-link :to="{ name: 'Contact' }">contact</router-link>
            </h4>
            <div>
              <div>
                <p class="footer__paragraph">OBA Congres Oosterdokskade 143 1011 DK Amsterdam</p>
              </div>
              <div>
                <a href="tel:0205230910" class="footer__link">T: 020-523 09 10</a>
                <a href="mailto:congres@oba.nl" class="footer__link">E: congres@oba.nl</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  name: "Footer",

  components: {
    Logo
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.footer {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  max-width: 1168px;
  margin: 0 auto;
  padding: 40px 16px 64px;
  box-sizing: content-box;
  color: $warm-grey;

  &__capsule {
    border-top: 1px solid $red;
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__link {
    @include font-book;
    line-height: 2;
    color: $warm-grey;
  }

  &__paragraph {
    line-height: 1.43;
    margin: 0 0 20px;
  }

  &__title {
    @include font-black;
    color: $black;

    &--desktop {
      display: none;
    }
  }

  &__container {
    display: flex;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: inherit;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;

    &--logo {
      margin-top: 64px;
      align-items: center;

      .footer__paragraph {
        max-width: 220px;
        text-align: center;
      }
    }

    &--contact {
      flex-direction: row;
      justify-content: space-between;
      min-width: 152px;
      font-size: 14px;

      .footer__title {
        font-size: 16px;
      }

      .footer__container {
        align-items: flex-end;

        div {
          flex-direction: row;

          div {
            flex-direction: column;

            &:last-child {
              padding-top: 20px;
            }
          }
        }
      }

      .footer__link {
        line-height: 1.43;
      }

      .footer__paragraph {
        max-width: 160px;
      }
    }
  }

  // media queries
  @include respond-to("s") {
    flex-direction: row;
    justify-content: space-between;
    max-height: 360px;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:first-of-type {
        width: 50%;
      }
    }

    &__section {
      .footer__container {
        flex-direction: column;
        align-items: initial;

        div {
          flex-direction: column;
        }
      }

      &--logo {
        margin-top: 20px;
        align-items: flex-start;

        .footer__paragraph {
          text-align: left;
        }
      }

      &--contact {
        .footer__paragraph {
          margin: 6px 0 20px;
        }

        .footer__container {
          div {
            div {
              &:last-child {
                padding-top: 0;
              }
            }
          }
        }
      }
    }

    &__title {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
      }
    }
  }
}
</style>
