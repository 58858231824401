<template>
  <header class="header">
    <div class="header__logo">
      <Logo />
    </div>
    <span ref="burger" class="header__burger" @click="toggleNavigation()"></span>
    <nav class="header__nav">
      <router-link :to="{ name: 'HomeOverview' }" class="header__link">home</router-link>
      <router-link :to="{ name: 'OnzeRuimtes' }" class="header__link">onze ruimtes</router-link>
      <router-link :to="{ name: 'UwEvent' }" class="header__link">uw event</router-link>
      <router-link :to="{ name: 'Contact' }" class="header__link">contact</router-link>
    </nav>
  </header>
</template>

<script>
import { Events } from "@/events";
import Logo from "@/components/Logo.vue";

export default {
  name: "Header",

  components: {
    Logo
  },

  mounted() {
    window.onscroll = () => {
      if (window.pageYOffset > 0) this.$el.classList.add("active");
      else this.$el.classList.remove("active");
    };
  },

  methods: {
    toggleNavigation() {
      Events.$emit("toggle-navigation");
      this.$refs.burger.classList.toggle("active");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

// header
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 0 16px;
  width: 100vw;
  height: 60px;
  background-color: $white;
  border-bottom: 1px solid $warm-grey-light;
  z-index: 999;

  &.active {
    position: fixed;
    border-bottom: 1px solid $red;
  }

  // burger menu button
  &__burger {
    display: inline-block;
    width: 36px;
    border: 0;
    border-bottom: 3px solid currentColor;
    outline: 0;
    padding: 0;
    background: none;
    cursor: pointer;
    transition: all $animated;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 100%;
      margin-bottom: 7px;
      border-bottom: 3px solid currentColor;
      transition: transform $animated;
    }

    // burger's active state
    &.active {
      width: 32px;
      border-bottom: 3px solid transparent;
      transform: translateY(7px);
      transition: transform $animated;

      &:before {
        border-width: 4px;
        transform: rotate(-404deg) translateY(2px) translateX(-4px);
        transition: transform $animated;
      }

      &:after {
        border-width: 4px;
        transform: rotate(404deg) translateY(-4px) translateX(-6px);
        transition: transform $animated;
      }
    }
  }

  &__nav {
    display: none;
    align-self: flex-start;
  }

  &__link {
    display: inline-flex;
    line-height: 32px;
    margin-right: 64px;
    color: $black;

    &:last-of-type {
      margin-right: 24px;
    }
  }

  // media queries
  @include respond-to("s") {
    position: absolute;
    margin-top: 16px;
    padding: 0 40px;
    height: 72px;
    border: none;
    background-color: transparent;

    &.active {
      position: absolute;
      border-bottom: none;
    }

    &__logo {
      align-self: flex-end;
    }

    &__burger {
      display: none;
    }

    &__nav {
      display: flex;
      margin-top: 16px;
    }
  }
}
</style>
