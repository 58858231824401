<template>
  <transition name="fade">
    <div>
      <Hero alt="eten" heading="eten en drinken" />
      <Gallery
        :type="6"
        :slides="slidesGallery"
        info="OBA Congres beschikt met restaurant Babel over een ervaren culinair team en een grote productiekeuken. Hier worden gasten culinair verrast. Van een simpel plateau met broodjes op de vergadertafel tot een borrel met lokale speciaalbieren, van een ontbijtsessie aan statafels tot een sit-down diner van zeven gangen; het culinaire team kan aan praktisch iedere wens voldoen. We denken graag met u mee.<br><br>In onze brochure vindt u enkele voorbeelden van onze catering. Deze arrangementen zijn ter inspiratie; wij behandelen iedere aanvraag als maatwerk.<br><br>Bekijk hier de <a href='https://www.oba.nl/content/dam/noord-holland/amsterdam/obacongres/oba-congres-brochure.pdf' target='_blank'>brochure</a> of neem <a href='#contact'>contact</a> met ons op voor meer informatie.<br><br>"
        brochure="true"
      />
      <Slider :type="3" heading="Ruimtes" :slides="slidesTop" />
      <Slider :type="6" heading="zalen" :slides="slidesBottom" />
      <Table heading="overzicht" :spaces="spaces" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Slider from "@/components/Slider.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo, meetingSpacesSlides, otherSpacesSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "EtenDrinken",

  components: {
    Hero,
    Gallery,
    Slider,
    Table,
    Form,
    Footer
  },

  data() {
    return {
      slidesGallery: [
        { delay: 0, bg: "Eten-1" },
        { delay: 100, bg: "Eten-2" },
        { delay: 200, bg: "Eten-3" },
        { delay: 300, bg: "Eten-4" },
        { delay: 400, bg: "Eten-5" },
        { delay: 500, bg: "Eten-6" }
      ],
      slidesTop: otherSpacesSlides,
      slidesBottom: meetingSpacesSlides,
      spaces: spacesInfo
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
