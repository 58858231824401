<template>
  <section class="slider__wrapper">
    <h2 class="slider__heading heading heading--2">{{ heading }}</h2>
    <div :class="['slider', `slider--${type}`]">
      <router-link
        v-for="(slide, key) in slides"
        :key="key"
        :to="slide.url"
        :class="['slider__slide', `slider__slide--${type}`]"
        data-aos="fade-up"
        :data-aos-delay="slide.delay"
      >
        <div class="slider__content-container">
          <div :class="['slider__content', `slider__content--${slide.bg}`]"></div>
        </div>
        <h4 v-if="slide.name" class="slider__title">{{ slide.name }}</h4>
        <p v-if="slide.showArea" class="slider__sub">
          {{ slide.area }} m
          <sup>2</sup>
        </p>
      </router-link>
    </div>
  </section>
</template>

<script>
import { tns } from "./../vendor/tiny-slider/tiny-slider.module.js";

export default {
  name: "Slider",

  props: ["type", "heading", "slides", "container"],

  data() {
    return {
      slider: undefined
    };
  },

  mounted() {
    this.createSlider();
  },

  beforeDestroy() {
    this.slider.destroy();
  },

  methods: {
    createSlider() {
      this.slider = tns({
        container: `.slider--${this.type}`,
        slideBy: 1,
        controls: false,
        nav: false,
        speed: 600,
        rewind: true,
        responsive: {
          "0": {
            items: 3,
            gutter: 16,
            autoplay: false,
            mouseDrag: true,
            fixedWidth: this.small().fixedWidth
          },
          "768": {
            items: this.medium().items,
            fixedWidth: this.medium().fixedWidth
          },
          "1201": {
            gutter: 32,
            items: this.large().items,
            fixedWidth: this.large().fixedWidth
          }
        }
      });
    },

    small() {
      if (this.type === 3) return { fixedWidth: 236 };
      else if (this.type === 4) return { fixedWidth: 236 };
      else if (this.type === 5) return { fixedWidth: 236 };
      else if (this.type === 6) return { fixedWidth: 168 };
      return { fixedWidth: false };
    },

    medium() {
      if (this.type === 3)
        return {
          items: 3,
          fixedWidth: false
        };
      else if (this.type === 4)
        return {
          items: 4,
          fixedWidth: false
        };
      else if (this.type === 5)
        return {
          items: 4,
          fixedWidth: 208
        };
      else if (this.type === 6)
        return {
          items: 4,
          fixedWidth: 172
        };
      return {
        items: 4,
        fixedWidth: false
      };
    },

    large() {
      if (this.type === 3)
        return {
          items: 3,
          fixedWidth: 368
        };
      else if (this.type === 4)
        return {
          items: 4,
          fixedWidth: 268
        };
      else if (this.type === 5)
        return {
          items: 5,
          fixedWidth: 208
        };
      else if (this.type === 6)
        return {
          items: 6,
          fixedWidth: 168
        };
      return {
        items: 4,
        fixedWidth: false
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";
@import "../assets/scss/slider";

.slider__slide {
  height: 276px;
  outline: none;

  &--3 {
    height: 276px;
  }

  &--4 {
    height: 364px;
  }

  &--5 {
    height: 298px;
  }

  &--6 {
    height: 252px;
  }
}

.slider__content-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider__heading {
  text-transform: lowercase;
}

.slider__content {
  background-color: $warm-grey;
  background-position: center;
  &:hover {
    animation: animate-slide 50s linear infinite;
  }
}

@for $i from 1 through 18 {
  .slider__content--#{$i} {
    background-image: url("../assets/images/Slider/#{$i}.jpg");
  }
}

.slider__sub {
  sup {
    top: -6px;
    left: -3px;
    font-size: 7px;
  }
}

@keyframes animate-slide {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.4) translateX(-5%);
  }

  40% {
    transform: scale(1.6) translateX(-10%);
  }

  60% {
    transform: scale(1.6) translateX(10%);
  }

  80% {
    transform: scale(1.4) translateX(5%);
  }
}
</style>
