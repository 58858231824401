<template>
  <transition name="fade">
    <div>
      <Hero alt="Hall-4" heading="6.4 Herenzaal" />
      <Gallery
        :type="6"
        :slides="gallery"
        heading="6.4 Herenzaal"
        info="De Herenzaal leent zich uitstekend voor vergaderingen of presentaties. De ruimte is ingericht met comfortabel designmeubilair en in te delen in diverse opstellingen, afgestemd op uw behoefte. De zaal is van alle gemakken voorzien om een optimale presentatie te geven.<br><br>Behoefte aan meer ruimte? De Herenzaal kan worden samengevoegd met de <a href='/vergaderzalen/6-3-singelzaal'>Singelzaal</a> en is zo de ideale ruimte voor grotere vergaderingen of presentaties.<br><br>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed
        url="https://my.matterport.com/show/?m=pZWLeMx89EN"
        combi-url="https://my.matterport.com/show/?m=wfZM82CQRUH"
        bg="hall-4"
      />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Hall4",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Hall-4-1" },
        { delay: 100, bg: "Hall-4-2" },
        { delay: 200, bg: "Hall-4-3" },
        { delay: 300, bg: "Hall-4-4" },
        { delay: 400, bg: "Hall-4-5" },
        { delay: 500, bg: "Hall-4-6" }
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(
        space => space.url.name === this.$options.name || space.url.name.replace("-", "") === this.$options.name
      );
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
