<template>
  <transition name="fade">
    <div>
      <Hero alt="Hall-1" heading="6.1 vergaderzaal" />
      <Gallery
        :type="6"
        :slides="gallery"
        heading="6.1 vergaderzaal"
        info="Deze vergaderzaal ligt in de noordoosthoek van het gebouw en heeft een ruim uitzicht op het spoor nabij het Centraal Station en het Conservatorium van Amsterdam. De ruimte leent zich uitstekend voor vergaderingen of presentaties. De zaal is ingericht met comfortabel designmeubilair en biedt ruimte voor verschillende opstellingen. De vergaderzaal is voorzien van alle gemakken om een optimale presentatie te geven.<br><br>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed url="https://my.matterport.com/show/?m=oM7mMUbqoTv" bg="hall-1" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Hall1",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Hall-1-1" },
        { delay: 100, bg: "Hall-1-2" },
        { delay: 200, bg: "Hall-1-3" },
        { delay: 300, bg: "Hall-1-4" },
        { delay: 400, bg: "Hall-1-5" },
        { delay: 500, bg: "Hall-1-6" }
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(
        space => space.url.name === this.$options.name || space.url.name.replace("-", "") === this.$options.name
      );
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
