<template>
  <main :class="['intro', `intro--${image}`]">
    <section class="intro__text" data-aos="fade-up">
      <h3 class="intro__heading heading heading--3">{{ heading }}</h3>
      <p v-html="copy"></p>
    </section>
    <div :class="['intro__image', `intro__image--${image}`]" data-aos="fade-up"></div>
  </main>
</template>

<script>
export default {
  name: "Intro",

  props: ["heading", "copy", "image"]
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.intro {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  padding: 0 16px 80px;
  max-width: $inner-max-width;
  p {
    margin-bottom: 0;
  }
  @include respond-to("s") {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 80px;
  }
  @include respond-to("m") {
    overflow: hidden;
    margin: 80px auto;
    padding: 0;
    height: 600px;
    &--onzeruimtes {
      height: 660px;
    }
  }
}

.intro__text {
  padding: 48px 0 20px;
  background: $white;
  @include respond-to("s") {
    width: 50%;
    padding: 48px 16px 20px 16px;
  }
  @include respond-to("m") {
    position: absolute;
    right: 64px;
    bottom: 0;
    max-width: 432px;
    padding: 40px 32px;
    z-index: 9;
  }
}

.intro__heading {
  margin-bottom: 20px;
}

.intro__image {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center center;
  background-image: url("../assets/images/Home/intro.jpg");
  @include respond-to("s") {
    flex: 1;
    width: 50%;
    height: 376px;
    margin: 48px 16px 20px 16px;
  }
  @include respond-to("m") {
    position: absolute;
    margin-top: 0 0 0 16px;
    width: calc(100% - 32px);
    height: inherit;
  }
  @include respond-to("l") {
    margin: 0;
    width: 100%;
  }
}

$background-images: (
  presenteren: "Presenteren/intro.jpg",
  eten: "Eten/intro.jpg",
  uwevent: "UwEvent/intro.jpg",
  contact: "Contact/intro.jpg",
  activiteiten: "Activiteiten/intro.jpg",
  congres: "Congres/intro.jpg",
  onzeruimtes: "OnzeRuimtes/intro.jpg",
  vergaderen: "Vergaderen/intro.jpg"
);
@each $name, $image in $background-images {
  .intro__image--#{$name} {
    background-image: url("../assets/images/#{$image}");
  }
}
</style>
