<template>
  <transition name="fade">
    <div>
      <Hero alt="Hall-6" heading="6.6 Prinsenzaal" />
      <Gallery
        :type="6"
        :slides="gallery"
        heading="6.6 Prinsenzaal"
        info="De Prinsenzaal leent zich uitstekend voor vergaderingen of presentaties. Deruimte is ingericht met comfortabel designmeubilair en in te delen in diverse opstellingen, afgestemd op uw behoefte. De zaal is van alle gemakken voorzien om een optimale presentatie te geven. Bovendien wordt u in de Prinsenzaal getrakteerd op een prachtig uitzicht over de stad.<br><br>Behoefte aan meer ruimte? De combinatie van de Prinsen- & <a href='/vergaderzalen/6-5-keizerzaal'>Keizerzaal</a> is de ideale ruimte voor grotere vergaderingen of presentaties.<br><br>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed
        url="https://my.matterport.com/show/?m=LdmcFbrAHNT"
        combi-url="https://my.matterport.com/show/?m=wfZM82CQRUH"
        bg="hall-6"
      />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Hall6",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Hall-6-1" },
        { delay: 100, bg: "Hall-6-2" },
        { delay: 200, bg: "Hall-6-3" },
        { delay: 300, bg: "Hall-6-4" },
        { delay: 400, bg: "Hall-6-5" },
        { delay: 500, bg: "Hall-6-6" }
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(
        space => space.url.name === this.$options.name || space.url.name.replace("-", "") === this.$options.name
      );
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
