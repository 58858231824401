<template>
  <transition name="fade">
    <div>
      <Hero alt="onzeRuimtes" heading="onze ruimtes" />
      <Intro
        heading="onze ruimtes"
        image="onzeruimtes"
        copy="Onze ruimtes lenen zich uitstekend voor presentaties, vergaderingen, workshops, congressen en private dining. Op onze zesde etage presenteren wij u graag vier nieuwe vergaderzalen met comfortabel designmeubilair en moderne faciliteiten.<br><br>De ruimtes zijn flexibel inzetbaar voor gezelschappen tot 90 personen. OBA Congres biedt ook zalen die zich goed lenen voor gezelschappen tot 20 personen, verspreid door het gebouw. Deze ruimtes zijn uitermate geschikt voor kleinere workshops en  vergaderingen. Voor grotere gezelschappen hebben we het OBA Theater met een capaciteit van 260 zitplaatsen. Het nieuwe OBA Forum heeft een uitschuifbare tribune en een balkon. Deze tribune heeft een capaciteit van 57 personen die met stoelen kan worden aangevuld."
      />
      <Slider :type="4" heading="Onze ruimtes" :slides="slides" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Intro from "@/components/Intro.vue";
import Slider from "@/components/Slider.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesOverviewSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "OnzeRuimtes",

  components: {
    Hero,
    Intro,
    Slider,
    Form,
    Footer
  },

  data() {
    return {
      slides: spacesOverviewSlides
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
