<template>
  <section class="gallery__wrapper">
    <main class="gallery__info">
      <h3 v-if="heading" class="gallery__heading heading heading--3">{{ heading }}</h3>
      <div class="gallery__intro" v-html="info"></div>
      <div class="gallery__data">
        <div v-if="area || persons">
          <div v-if="area" class="gallery__data-block">
            <p class="title">oppervlakte:</p>
            <p>
              {{ area }} m
              <sup>2</sup>
            </p>
          </div>
          <div v-if="persons" class="gallery__data-block">
            <p class="title">capaciteit:</p>
            <p>{{ persons }} personen</p>
          </div>
        </div>
        <div v-if="options">
          <div class="gallery__data-block">
            <p class="title">beschikbaar:</p>
            <p v-for="(option, key) in options" :key="key">
              <span>{{ option }}</span>
            </p>
          </div>
        </div>

        <div v-if="prices">
          <div class="gallery__data-block gallery__data-block--prices">
            <p class="title">{{ prices && prices.length > 1 ? "prijzen" : "prijs" }}:</p>
            <div class="list">
              <p v-for="(price, key) in prices" :key="key">
                <span v-html="price"></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <a
        v-if="brochure"
        class="gallery__brochure"
        href="https://www.oba.nl/content/dam/noord-holland/amsterdam/obacongres/oba-congres-brochure.pdf"
        target="_blank"
        >bekijk brochure</a
      >
    </main>

    <div class="gallery gallery--mobile">
      <div
        v-for="(slide, key) in slides"
        :key="key"
        class="gallery__slide"
        data-aos="fade-up"
        :data-aos-delay="slide.delay"
      >
        <div :class="['gallery__content', `gallery__content--${slide.bg}`]"></div>
      </div>
    </div>

    <div class="gallery gallery--desktop">
      <div
        v-for="(slide, key) in slides"
        :key="key"
        class="gallery__slide"
        data-aos="fade-up"
        :data-aos-delay="slide.delay"
      >
        <div :class="['gallery__content', `gallery__content--${slide.bg}`]"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider.module.js";

export default {
  name: "Gallery",

  props: ["heading", "info", "area", "persons", "options", "prices", "slides", "brochure"],

  data() {
    return {
      gallery: undefined
    };
  },

  mounted() {
    this.gallery = tns({
      container: ".gallery--mobile",
      slideBy: 1,
      controls: false,
      nav: false,
      speed: 600,
      autoplayHoverPause: true,
      rewind: true,
      items: 6,
      gutter: 16,
      autoplay: true,
      mouseDrag: true,
      fixedWidth: 168
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";
@import "../assets/scss/slider";

.gallery {
  &__wrapper {
    margin-top: 24px;
    margin-bottom: 0;
  }

  &--mobile {
    .gallery__slide {
      height: 168px;
    }
  }

  &--desktop {
    display: none;
  }

  &__heading {
    margin-bottom: 24px;
  }

  &__data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    overflow-x: hidden;

    & > * {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    & > *:nth-child(1) {
      grid-column: auto / span 1;
    }
    & > *:nth-child(2) {
      grid-column: auto/ span 1;
    }
    & > *:nth-child(3) {
      grid-column: auto/ span 2;
    }

    p {
      margin: 0;

      &:last-of-type,
      span {
        color: $warm-grey;
      }
    }

    p.title {
      color: $black;
    }

    sup {
      margin-left: -4px;
    }
  }

  &__data-block--prices {
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }

  &__brochure {
    display: inline-block;
    position: relative;
    margin-top: 24px;
    color: $red;
    transition: transform $animated-short;

    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 10px;
      margin: 3px 0 0 12px;
      background-image: url("../assets/images/red-arrow-right.svg");
      background-size: cover;
      transition: transform $animated;
    }

    &:hover {
      &:after {
        transform: translateX(8px);
      }
    }
  }

  &__content {
    background-color: $warm-grey;
    background-position: center;
    cursor: initial;

    &--Forum-1 {
      background-image: url("../assets/images/Forum/square1.jpg");
    }

    &--Forum-2 {
      background-image: url("../assets/images/Forum/rectanglemiddle.jpg");
    }

    &--Forum-3 {
      background-image: url("../assets/images/Forum/square2.jpg");
    }

    &--Forum-4 {
      background-image: url("../assets/images/Forum/rectangle.jpg");
    }

    &--Forum-5 {
      background-image: url("../assets/images/Forum/square3.jpg");
    }

    &--Forum-6 {
      background-image: url("../assets/images/Forum/square4.jpg");
    }

    &--Vergaderzalen-1 {
      background-image: url("../assets/images/Vergaderzalen/square1.jpg");
    }

    &--Vergaderzalen-2 {
      background-image: url("../assets/images/Vergaderzalen/rectanglemiddle.jpg");
    }

    &--Vergaderzalen-3 {
      background-image: url("../assets/images/Vergaderzalen/square2.jpg");
    }

    &--Vergaderzalen-4 {
      background-image: url("../assets/images/Vergaderzalen/rectangle.jpg");
    }

    &--Vergaderzalen-5 {
      background-image: url("../assets/images/Vergaderzalen/square3.jpg");
    }

    &--Vergaderzalen-6 {
      background-image: url("../assets/images/Vergaderzalen/square4.jpg");
    }

    &--Theater-1 {
      background-image: url("../assets/images/Theater/square1.jpg");
    }

    &--Theater-2 {
      background-image: url("../assets/images/Theater/rectanglemiddle.jpg");
    }

    &--Theater-3 {
      background-image: url("../assets/images/Theater/square2.jpg");
    }

    &--Theater-4 {
      background-image: url("../assets/images/Theater/rectangle.jpg");
    }

    &--Theater-5 {
      background-image: url("../assets/images/Theater/square3.jpg");
    }

    &--Theater-6 {
      background-image: url("../assets/images/Theater/square4.jpg");
    }

    &--Dining-1 {
      background-image: url("../assets/images/Dining/square1.jpg");
    }

    &--Dining-2 {
      background-image: url("../assets/images/Dining/rectanglemiddle.jpg");
    }

    &--Dining-3 {
      background-image: url("../assets/images/Dining/square2.jpg");
    }

    &--Dining-4 {
      background-image: url("../assets/images/Dining/rectangle.jpg");
    }

    &--Dining-5 {
      background-image: url("../assets/images/Dining/square3.jpg");
    }

    &--Dining-6 {
      background-image: url("../assets/images/Dining/square4.jpg");
    }

    &--Hall-3-1 {
      background-image: url("../assets/images/Hall-3/square1.jpg");
    }

    &--Hall-3-2 {
      background-image: url("../assets/images/Hall-3/rectanglemiddle.jpg");
    }

    &--Hall-3-3 {
      background-image: url("../assets/images/Hall-3/square2.jpg");
    }

    &--Hall-3-4 {
      background-image: url("../assets/images/Hall-3/rectangle.jpg");
    }

    &--Hall-3-5 {
      background-image: url("../assets/images/Hall-3/square3.jpg");
    }

    &--Hall-3-6 {
      background-image: url("../assets/images/Hall-3/square4.jpg");
    }

    &--Hall-4-1 {
      background-image: url("../assets/images/Hall-4/square1.jpg");
    }

    &--Hall-4-2 {
      background-image: url("../assets/images/Hall-4/rectanglemiddle.jpg");
    }

    &--Hall-4-3 {
      background-image: url("../assets/images/Hall-4/square2.jpg");
    }

    &--Hall-4-4 {
      background-image: url("../assets/images/Hall-4/rectangle.jpg");
    }

    &--Hall-4-5 {
      background-image: url("../assets/images/Hall-4/square3.jpg");
    }

    &--Hall-4-6 {
      background-image: url("../assets/images/Hall-4/square4.jpg");
    }

    &--Hall-2-1 {
      background-image: url("../assets/images/Hall-2/square1.jpg");
    }

    &--Hall-2-2 {
      background-image: url("../assets/images/Hall-2/rectanglemiddle.jpg");
    }

    &--Hall-2-3 {
      background-image: url("../assets/images/Hall-2/square2.jpg");
    }

    &--Hall-2-4 {
      background-image: url("../assets/images/Hall-2/rectangle.jpg");
    }

    &--Hall-2-5 {
      background-image: url("../assets/images/Hall-2/square3.jpg");
    }

    &--Hall-2-6 {
      background-image: url("../assets/images/Hall-2/square4.jpg");
    }

    &--Hall-6-1 {
      background-image: url("../assets/images/Hall-6/square1.jpg");
    }

    &--Hall-6-2 {
      background-image: url("../assets/images/Hall-6/rectanglemiddle.jpg");
    }

    &--Hall-6-3 {
      background-image: url("../assets/images/Hall-6/square2.jpg");
    }

    &--Hall-6-4 {
      background-image: url("../assets/images/Hall-6/rectangle.jpg");
    }

    &--Hall-6-5 {
      background-image: url("../assets/images/Hall-6/square3.jpg");
    }

    &--Hall-6-6 {
      background-image: url("../assets/images/Hall-6/square4.jpg");
    }

    &--Hall-1-1 {
      background-image: url("../assets/images/Hall-1/square1.jpg");
    }

    &--Hall-1-2 {
      background-image: url("../assets/images/Hall-1/rectanglemiddle.jpg");
    }

    &--Hall-1-3 {
      background-image: url("../assets/images/Hall-1/square2.jpg");
    }

    &--Hall-1-4 {
      background-image: url("../assets/images/Hall-1/rectangle.jpg");
    }

    &--Hall-1-5 {
      background-image: url("../assets/images/Hall-1/square3.jpg");
    }

    &--Hall-1-6 {
      background-image: url("../assets/images/Hall-1/square4.jpg");
    }

    &--Hall-5-1 {
      background-image: url("../assets/images/Hall-5/square1.jpg");
    }

    &--Hall-5-2 {
      background-image: url("../assets/images/Hall-5/rectanglemiddle.jpg");
    }

    &--Hall-5-3 {
      background-image: url("../assets/images/Hall-5/square2.jpg");
    }

    &--Hall-5-4 {
      background-image: url("../assets/images/Hall-5/rectangle.jpg");
    }

    &--Hall-5-5 {
      background-image: url("../assets/images/Hall-5/square3.jpg");
    }

    &--Hall-5-6 {
      background-image: url("../assets/images/Hall-5/square4.jpg");
    }
    &--Eten-1 {
      background-image: url("../assets/images/Eten/square1.jpg");
    }

    &--Eten-2 {
      background-image: url("../assets/images/Eten/rectanglemiddle.jpg");
    }

    &--Eten-3 {
      background-image: url("../assets/images/Eten/square2.jpg");
    }

    &--Eten-4 {
      background-image: url("../assets/images/Eten/rectangle.jpg");
    }

    &--Eten-5 {
      background-image: url("../assets/images/Eten/square3.jpg");
    }

    &--Eten-6 {
      background-image: url("../assets/images/Eten/square4.jpg");
    }

    &[class*="--"] {
      background-position: center;
      background-size: cover;
    }
  }

  @include respond-to("s") {
    &__wrapper {
      display: flex;
      margin-top: 40px;
    }

    &__data {
      &-block {
        width: 100%;
      }
    }

    &--mobile {
      display: none;
    }

    &--desktop {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 368px;
      margin-top: 0;

      .gallery__slide {
        @include sizepx(168);
        margin: 0 0 16px 16px;
      }
    }

    &__info {
      flex: 1;
      width: calc(50% - 32px);
      z-index: 99;
    }
  }

  @include respond-to("m") {
    &__wrapper {
      margin-top: 80px;
      margin-bottom: 40px;
    }

    &--desktop {
      justify-content: initial;
      position: relative;
      min-width: 672px;
      min-height: 488px;
      margin-top: 0;

      .gallery__slide {
        @include sizepx(152);
        position: absolute;
        margin: 0;

        &:nth-of-type(1) {
          bottom: 168px;
          right: 420px;
        }

        &:nth-of-type(2) {
          @include size(236px, 100%);
          top: 0;
          right: 168px;
        }

        &:nth-of-type(3) {
          top: 0;
          right: 0;
        }

        &:nth-of-type(4) {
          @include sizepx(236, 152);
          bottom: 0;
          right: 420px;
        }

        &:nth-of-type(5) {
          top: 168px;
          right: 0;
        }

        &:nth-of-type(6) {
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  @include respond-to("l") {
    &__wrapper {
      margin-bottom: 60px;
    }

    &--desktop {
      min-width: 800px;
      min-height: 568px;

      .gallery__slide {
        @include sizepx(168);

        &:nth-of-type(1) {
          bottom: 200px;
          right: 500px;
        }

        &:nth-of-type(2) {
          @include size(268px, 100%);
          right: 200px;
        }

        &:nth-of-type(4) {
          @include sizepx(268, 168);
          right: 500px;
        }

        &:nth-of-type(5) {
          top: 200px;
        }
      }
    }
  }
}

.gallery__intro {
  line-height: 1.5;
}
</style>
