<template>
  <transition name="fade">
    <div>
      <Hero alt="presenteren" heading="presenteren" />
      <Intro
        heading="presenteren"
        image="presenteren"
        copy="Van filmvertoningen tot laptoppresentaties, van kwartaalcijfers tot jaarplan, voor ieder soort presentatie en elke groepsgrootte tot 260 personen hebben wij een passende ruimte. Onze ruimtes zijn voorzien van moderne presentatiefaciliteiten en OBA Congres heeft verschillende technische specialisten om u ter plaatse te ondersteunen. Zo zijn bijna al onze ruimtes voorzien van een audio video conference set zodat deelnemers ook op afstand de presentatie kunnen volgen.<br/><br/>
        Het OBA Theater is zeer geschikt voor grotere presentaties en voorstellingen. bovendien heeft u de mogelijkheid voor livestream en/of opnames in zaal."
      />
      <Slider :type="3" heading="Ruimtes" :slides="slidesTop" />
      <Slider :type="6" heading="zalen" :slides="slidesBottom" />
      <Table heading="overzicht" :spaces="spaces" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Intro from "@/components/Intro.vue";
import Slider from "@/components/Slider.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo, meetingSpacesSlides, otherSpacesSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Presenteren",

  components: {
    Hero,
    Intro,
    Slider,
    Table,
    Form,
    Footer
  },

  data() {
    return {
      slidesTop: otherSpacesSlides,
      slidesBottom: meetingSpacesSlides,
      spaces: spacesInfo
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
