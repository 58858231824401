<template>
  <transition name="fade">
    <div>
      <Hero alt="vergaderen" heading="vergaderen" />
      <Intro
        heading="vergaderen"
        image="vergaderen"
        copy="Vergaderen kan bij OBA Congres in zalen van 2 tot 90 personen. De vergaderruimtes zijn flexibel in te delen en bieden voorzieningen zoals een beamer, flip-over, whiteboard, internetaansluiting en verder zijn bijna al onze ruimtes voorzien van een audio video conference set waarmee je kan inbellen op bijvoorbeeld Teams en Zoom.<br/><br/>
        Heeft u interesse en wilt u vrijblijvend een offerte ontvangen of direct een zaal reserveren? Neem dan <a href='#contact'>contact</a> met ons op. We helpen u graag."
      />
      <Slider :type="6" heading="zalen" :slides="slides" />
      <Table heading="overzicht faciliteiten" :spaces="spaces.filter(space => space.size === 'medium')" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Intro from "@/components/Intro.vue";
import Slider from "@/components/Slider.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo, meetingSpacesSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Vergaderen",

  components: {
    Hero,
    Intro,
    Slider,
    Table,
    Form,
    Footer
  },

  data() {
    return {
      slides: meetingSpacesSlides,
      spaces: spacesInfo
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      AOS.init({
        offset: 0,
        duration: 600,
        anchorPlacement: "top-bottom",
        easing: "cubic-bezier(0.77, 0, 0.175, 1)"
      });
    });
  }
};
</script>
