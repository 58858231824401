<template>
  <transition name="fade">
    <div>
      <Hero alt="static" heading="static" />
      <Intro
        heading="Static"
        copy="Heeft u behoefte aan meer informatie en/of wilt u een vrijblijvende offerte ontvangen voor uw zakelijke evenement bij OBA Congres? Aarzel dan niet contact met ons op te nemen, we doen ons best u vanaf het eerste moment alle zorg uit handen te nemen. Tevens streven wij er naar u binnen 24 uur een offerte op maat te sturen. <br> <br> De Centrale OBA is dagelijks geopend van 08:00 uur tot 22:00 uur. Het team van OBA Congres vindt u op de zesde verdieping en is dagelijks tijdens kantooruren bereikbaar via onderstaande contactgegevens."
      />
      <Info
        heading="Bereikbaarheid"
        copy1="OBA Congres aan de Oosterdokskade is goed bereikbaar met het openbaar vervoer. Neem de metro, tram, bus, pont of trein naar Amsterdam Centraal Station. Vanaf daar is het 5 tot 10 minuten lopen in oostelijke richting over het Oosterdokseiland.<br/><br/>Voor fietsers is er naast de OBA een ondergrondse, bewaakte fietsenstalling. Uw fiets staat daar gratis veilig en droog."
        copy2="Komt u met de auto? Parkeren is mogelijk in Parking Centrum Oosterdok, de goedkoopste parkeergarage in de binnenstad. Deze parkeergarage is eenvoudig bereikbaar vanaf de ring A10. Vanaf de garage is het slechts nog enkele minuten lopen."
        copy3=""
      />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Intro from "@/components/Intro.vue";
import Info from "@/components/Info.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import AOS from "aos";

export default {
  name: "Static",

  components: {
    Hero,
    Intro,
    Info,
    Form,
    Footer
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
