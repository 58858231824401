<template>
  <section class="menu">
    <div ref="trigger" class="menu__link menu__link--head">
      Uw event
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="8">
        <defs>
          <path id="a" d="M8 5l-8 5V0z" />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="rotate(90 5 5)">
          <mask id="b" fill="#fff">
            <use xlink:href="#a" />
          </mask>
          <use fill="#000" xlink:href="#a" />
          <g fill="#FFF" mask="url(#b)">
            <path d="M0 0h8v10H0z" />
          </g>
        </g>
      </svg>
    </div>
    <div ref="menu" class="menu__dropdown">
      <router-link :to="{ name: 'Vergaderen' }" class="menu__link">Vergaderen</router-link>
      <router-link :to="{ name: 'CongresBeurs' }" class="menu__link">Congres & beurs</router-link>
      <router-link :to="{ name: 'Presenteren' }" class="menu__link">Presenteren</router-link>
      <router-link :to="{ name: 'EtenDrinken' }" class="menu__link">Eten & drinken</router-link>
      <router-link :to="{ name: 'Activiteiten' }" class="menu__link">Activiteiten</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "Menu",

  mounted() {
    const trigger = this.$refs.trigger;
    const menu = this.$refs.menu;

    trigger.addEventListener("click", () => {
      trigger.classList.toggle("active");
      menu.classList.toggle("active");

      const links = menu.children;

      for (const link in Object.keys(links)) {
        links[link].classList.toggle("active");
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.menu {
  display: flex;
  flex-direction: column;
  max-width: $inner-max-width;

  &__dropdown {
    display: flex;
    flex-direction: column;
    max-height: 0;
    transition: all $animated;

    &.active {
      max-height: 240px;
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background-color: $warm-grey;
    color: rgba(255, 255, 255, 0);
    transition: background-color $animated, color $animated;
    text-transform: lowercase;
    cursor: pointer;

    &:hover {
      background-color: $white;
      color: $black;
    }

    &.active {
      color: $white;

      &:hover {
        color: $black;
      }
    }

    &--head {
      @include font-black;
      background-color: $warm-grey;
      color: $white;
      z-index: 9;

      &:hover {
        background-color: $warm-grey;
        color: $white;
      }

      svg {
        margin-left: 15px;
        transition: all $animated;
      }

      &.active {
        background-color: $white;
        color: $black;

        svg {
          transform: rotate(-180deg);

          #b {
            fill: $black;
          }
        }
      }
    }
  }

  // media queries
  @include respond-to("s") {
    flex-direction: row;
    width: 100vw;

    &__dropdown {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-height: initial;
      width: 100vw;
      transition: none;
    }

    &__link {
      position: relative;
      width: 100%;
      height: 80px;
      color: $white;

      &:hover {
        &:after {
          opacity: 0;
        }
      }

      &:after {
        content: "";
        position: absolute;
        right: -1px;
        width: 1px;
        height: 24px;
        background-color: $white;
        transition: all $animated-short;
        z-index: 9;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }

      &--head {
        display: none;
      }
    }
  }

  // media queries
  @include respond-to("m") {
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    padding: 0 16px;

    &__dropdown {
      flex: 1;
      width: 100%;
    }

    &__link {
      font-size: 20px;
      color: $black;
      background-color: rgba(255, 255, 255, 0.5);

      &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:after {
        background-color: $warm-grey;
      }

      &:hover {
        background-color: $warm-grey;
        color: $white;

        &:after {
          background-color: $white;
        }
      }
    }
  }

  // media queries
  @include respond-to("l") {
    padding: 0;
  }
}
</style>
