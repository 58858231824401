<template>
  <section class="table">
    <h3 class="table__heading heading heading--3">{{ heading }}</h3>
    <hr />
    <br />
    <div v-for="(space, i) in spaces" :key="i" class="table__container">
      <div class="table__column contains-name">
        <div class="table__row" data-aos="fade-up">
          <p class="table__cell">{{ space.name }}</p>
          <router-link v-if="space.url" :to="space.url" class="table__cell">bekijk zaal</router-link>
        </div>
      </div>
      <div class="table__column contains-area-and-persons">
        <div class="table__column" data-aos="fade-up">
          <p class="table__cell contains-area">
            <strong>{{ space.area }}</strong>
            <span
              >m
              <sup>2</sup>
            </span>
          </p>
          <p class="table__cell contains-persons">
            <strong>{{ space.persons }}</strong>
            <span>pers.</span>
          </p>
        </div>
      </div>
      <div class="table__column contains-options">
        <div class="table__inner" data-aos="fade-up">
          <div class="table__inner">
            <span v-for="(option, k) in space.options" :key="k">{{ option }}</span>
          </div>
        </div>
      </div>

      <div class="table__column contains-prices">
        <div class="table__row" data-aos="fade-up">
          <div class="table__inner">
            <span v-for="(price, k) in space.prices" :key="k" v-html="price"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Table",

  props: ["heading", "spaces"]
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.table {
  max-width: 768px;
  margin: 0 auto 80px;
  padding: 0 16px;
  gap: 16px;
  @include respond-to("s") {
    margin: 0 auto 128px;
  }

  // Layout
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    @include respond-to("s") {
      grid-template-columns: minmax(auto, 168px) 2fr 1fr 3fr;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 120px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    min-height: 96px;
    gap: 16px;
  }

  &__inner {
    display: grid;
    gap: 0 10px;
  }

  &__cell {
    min-height: 24px;
    line-height: 1;
    margin: 0;
  }

  // Styling
  &__heading {
    margin-bottom: 32px;
    font-size: 24px;
  }

  &__column {
    &.contains-name {
      a {
        margin-top: 4px;
        font-size: 12px;
        &:after {
          content: "";
          position: absolute;
          width: 8px;
          height: 10px;
          margin: 1px 0 0 12px;
          background-image: url("../assets/images/red-arrow-right.svg");
          background-size: cover;
          transition: transform $animated;
        }
        &:hover,
        &:focus {
          &:after {
            transform: translateX(8px);
          }
        }
      }
    }

    &.contains-area-and-persons {
      font-size: 18px;
      color: $warm-grey;
      @include respond-to("s") {
        font-size: 36px;
        strong {
          @include font-book;
        }
      }
      span {
        margin-left: 2px;
        font-size: 16px;
      }
      sup {
        @include font-black;
        top: -7px;
        left: -4px;
        font-size: 6px;
      }
    }

    &.contains-options,
    &.contains-prices {
      font-size: 10px;
      line-height: 1.4;
      color: $warm-grey;
    }

    &.contains-prices {
      display: none;
      @include respond-to("s") {
        display: flex;
        .table__inner {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}
</style>
