<template>
  <section class="info">
    <h3 class="info__heading heading heading--3" data-aos="fade-up">{{ heading }}</h3>
    <div class="info__info">
      <div v-if="copy1" class="info__copy">
        <p data-aos="fade-up" data-aos-delay="100" v-html="copy1"></p>
        <template v-if="links">
          <router-link
            v-for="(link, index) in links"
            :key="index"
            :to="link.url"
            class="info__link"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {{ link.name }}
          </router-link>
        </template>
      </div>
      <div v-if="copy2" class="info__copy">
        <p data-aos="fade-up" data-aos-delay="200" v-html="copy2"></p>
      </div>
      <div v-if="copy3" class="info__copy">
        <p data-aos="fade-up" data-aos-delay="300" v-html="copy3"></p>
      </div>
      <div v-if="logo" class="info__logo" data-aos="fade-up" data-aos-delay="300">
        <Logo type="xl" />
      </div>
    </div>
  </section>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  name: "Info",

  components: {
    Logo
  },

  props: ["heading", "copy1", "copy2", "copy3", "links", "logo", "margin"],

  mounted() {
    this.$el.style.margin = this.margin;
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.info {
  margin: 0 auto 40px;
  padding: 0 16px;
  max-width: $inner-max-width;
  gap: 16px;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__link {
    display: block;
    position: relative;
    margin-top: 20px;

    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 10px;
      margin: 5px 0 0 12px;
      background-image: url("../assets/images/red-arrow-right.svg");
      background-size: cover;
    }
  }

  &__logo {
    flex: 1;
    margin: 40px auto 0;
  }

  // media queries
  @include respond-to("s") {
    &__info {
      flex-direction: row;
      gap: 32px;
    }

    &__copy {
      flex: 1;
    }
  }

  @include respond-to("m") {
    margin-bottom: 80px;
  }

  @include respond-to("l") {
    margin: 0 auto 120px;
    padding: 0;

    &__info {
      flex-direction: row;
    }

    &__logo {
      flex: 1;
      margin: -40px auto 0 20%;
    }
  }
}
</style>
