<template>
  <transition name="fade">
    <div>
      <Hero alt="uwevent" heading="uw event" />
      <Intro
        heading="uw event"
        image="uwevent"
        copy="Bent u op zoek naar een intieme ruimte om met uw afdeling te vergaderen? Wilt u nieuwe plannen aan uw zakenrelaties presenteren en trakteert u ze vervolgens op een lunch? Wilt u een inspiratiesessie met uw organisatie en napraten tijdens een borrel? Of bent u op zoek naar een unieke plek om te dineren met een gezelschap? OBA Congres helpt u graag bij het vinden van de geschikte locatie voor uw evenement."
      />
      <Slider :type="5" heading="Onze ruimtes" :slides="slides" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Intro from "@/components/Intro.vue";
import Slider from "@/components/Slider.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import AOS from "aos";

export default {
  name: "UwEvent",

  components: {
    Hero,
    Intro,
    Slider,
    Form,
    Footer
  },

  data() {
    return {
      slides: [
        {
          url: { name: "Vergaderen" },
          name: "vergaderen",
          persons: "20",
          delay: 0,
          bg: 14
        },
        {
          url: { name: "CongresBeurs" },
          name: "congres & beurs",
          persons: "90",
          delay: 100,
          bg: 15
        },
        {
          url: { name: "Presenteren" },
          name: "presenteren",
          places: "260",
          delay: 200,
          bg: 16
        },
        {
          url: { name: "EtenDrinken" },
          name: "eten & drinken",
          area: "60",
          delay: 300,
          bg: 17
        },
        {
          url: { name: "Activiteiten" },
          name: "activiteiten",
          area: "60",
          delay: 400,
          bg: 18
        }
      ]
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
