<template>
  <transition name="fade">
    <div>
      <Hero heading="OBA Congres" />
      <Menu />
      <Banner
        v-if="false"
        heading="Alle OBA-vestigingen zijn tijdelijk gesloten"
        :copy="
          `<p>De sluiting is een maatregel van de overheid om verspreiding van het coronavirus tegen te gaan.<br/>Volg de berichten op deze website, onze social media en in onze nieuwsbrieven voor nieuwe informatie.<br/>Op <a href='https://www.oba.nl/online' target='_blank'>oba.nl/online </a>vind je ons online aanbod.</p>`
        "
        logo="true"
      />
      <Intro
        heading="OBA Congres"
        :copy="
          `OBA Congres is uw partner voor presentaties en evenementen in hartje Amsterdam. Als onderdeel van OBA Oosterdok bieden wij in ons indrukwekkende gebouw verschillende ruimtes met moderne faciliteiten. De zalen lenen zich uitstekend voor presentaties, vergaderingen, workshops en congressen. Al onze zalen zijn toegankelijk voor minder valide personen. Tijdens uw evenement nemen wij alle zorg uit handen, zodat u op de dag zelf ontspannen alle aandacht op uw gasten kunt richten.<br/><br/>
          Het OBA Theater, dat met een capaciteit van 260 zitplaatsen, geschikt is voor grotere presentaties en voorstellingen heeft ook de mogelijkheid voor livestream en/of opnames.<br/><br/>
          OBA Oosterdok is goed bereikbaar, het ligt op een steenworp afstand van het Centraal Station Amsterdam en ook pal naast een ondergrondse parkeergarage.`
        "
      />
      <Slider container=".slider--4" :type="4" heading="de ruimtes" :slides="slides" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Hero from "@/components/Hero.vue";
import Menu from "@/components/Menu.vue";
import Intro from "@/components/Intro.vue";
import Slider from "@/components/Slider.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesOverviewSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "HomeOverview",

  components: {
    Banner,
    Hero,
    Intro,
    Menu,
    Slider,
    Form,
    Footer
  },

  data() {
    return {
      slides: spacesOverviewSlides
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
