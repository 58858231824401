// Format: ES MODULE
// Version: 2.7.2

// helper functions
import "./helpers/keys";
import "./helpers/childNode.remove";
import { raf } from "./helpers/raf";
import { caf } from "./helpers/caf";
import { extend } from "./helpers/extend";
import { checkStorageValue } from "./helpers/checkStorageValue";
import { setLocalStorage } from "./helpers/setLocalStorage";
import { getSlideId } from "./helpers/getSlideId";
import { calc } from "./helpers/calc";
import { subpixelLayout } from "./helpers/subpixelLayout";
import { mediaquerySupport } from "./helpers/mediaquerySupport";
import { createStyleSheet } from "./helpers/createStyleSheet";
import { addCSSRule } from "./helpers/addCSSRule";
import { getCssRulesLength } from "./helpers/getCssRulesLength";
import { toDegree } from "./helpers/toDegree";
import { getTouchDirection } from "./helpers/getTouchDirection";
import { forEachNodeList } from "./helpers/forEachNodeList";
import { hasClass } from "./helpers/hasClass";
import { addClass } from "./helpers/addClass";
import { removeClass } from "./helpers/removeClass";
import { hasAttr } from "./helpers/hasAttr";
import { getAttr } from "./helpers/getAttr";
import { setAttrs } from "./helpers/setAttrs";
import { removeAttrs } from "./helpers/removeAttrs";
import { removeElementStyles } from "./helpers/removeElementStyles";
import { arrayFromNodeList } from "./helpers/arrayFromNodeList";
import { hideElement } from "./helpers/hideElement";
import { showElement } from "./helpers/showElement";
import { isVisible } from "./helpers/isVisible";
import { whichProperty } from "./helpers/whichProperty";
import { getEndProperty } from "./helpers/getEndProperty";
import { addEvents } from "./helpers/addEvents";
import { removeEvents } from "./helpers/removeEvents";
import { Events } from "./helpers/events";
import { jsTransform } from "./helpers/jsTransform";

// check browser version and local storage
// if browser upgraded,
// 1. delete browser ralated data from local storage and
// 2. recheck these options and save them to local storage
var browserInfo = navigator.userAgent,
  localStorageAccess = true,
  tnsStorage = {};

// tC => calc
// tSP => subpixel
// tMQ => mediaquery
// tTf => transform
// tTDu => transitionDuration
// tTDe => transitionDelay
// tADu => animationDuration
// tADe => animationDelay
// tTE => transitionEnd
// tAE => animationEnd
try {
  tnsStorage = localStorage;
  // remove storage when browser version changes
  if (tnsStorage["tnsApp"] && tnsStorage["tnsApp"] !== browserInfo) {
    [
      "tC",
      "tSP",
      "tMQ",
      "tTf",
      "tTDu",
      "tTDe",
      "tADu",
      "tADe",
      "tTE",
      "tAE"
    ].forEach(function(item) {
      tnsStorage.removeItem(item);
    });
  }
  // update browserInfo
  tnsStorage["tnsApp"] = browserInfo;
} catch (e) {
  localStorageAccess = false;
}

// reset tnsStorage when localStorage is null (on some versions of Chrome Mobile #134)
// https://stackoverflow.com/questions/8701015/html-localstorage-is-null-on-android-when-using-webview
if (localStorageAccess && !localStorage) {
  tnsStorage = {};
}

// get browser related data from local storage if they exist
// otherwise, run the functions again and save these data to local storage
// checkStorageValue() convert non-string value to its original value: 'true' > true
var doc = document,
  win = window,
  KEYS = {
    ENTER: 13,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
  },
  CALC =
    checkStorageValue(tnsStorage["tC"]) ||
    setLocalStorage("tC", calc(), localStorageAccess),
  SUBPIXEL =
    checkStorageValue(tnsStorage["tSP"]) ||
    setLocalStorage("tSP", subpixelLayout(), localStorageAccess),
  CSSMQ =
    checkStorageValue(tnsStorage["tMQ"]) ||
    setLocalStorage("tMQ", mediaquerySupport(), localStorageAccess),
  TRANSFORM =
    checkStorageValue(tnsStorage["tTf"]) ||
    setLocalStorage(
      "tTf",
      whichProperty([
        "transform",
        "WebkitTransform",
        "MozTransform",
        "msTransform",
        "OTransform"
      ]),
      localStorageAccess
    ),
  TRANSITIONDURATION =
    checkStorageValue(tnsStorage["tTDu"]) ||
    setLocalStorage(
      "tTDu",
      whichProperty([
        "transitionDuration",
        "WebkitTransitionDuration",
        "MozTransitionDuration",
        "OTransitionDuration"
      ]),
      localStorageAccess
    ),
  TRANSITIONDELAY =
    checkStorageValue(tnsStorage["tTDe"]) ||
    setLocalStorage(
      "tTDe",
      whichProperty([
        "transitionDelay",
        "WebkitTransitionDelay",
        "MozTransitionDelay",
        "OTransitionDelay"
      ]),
      localStorageAccess
    ),
  ANIMATIONDURATION =
    checkStorageValue(tnsStorage["tADu"]) ||
    setLocalStorage(
      "tADu",
      whichProperty([
        "animationDuration",
        "WebkitAnimationDuration",
        "MozAnimationDuration",
        "OAnimationDuration"
      ]),
      localStorageAccess
    ),
  ANIMATIONDELAY =
    checkStorageValue(tnsStorage["tADe"]) ||
    setLocalStorage(
      "tADe",
      whichProperty([
        "animationDelay",
        "WebkitAnimationDelay",
        "MozAnimationDelay",
        "OAnimationDelay"
      ]),
      localStorageAccess
    ),
  TRANSITIONEND =
    checkStorageValue(tnsStorage["tTE"]) ||
    setLocalStorage(
      "tTE",
      getEndProperty(TRANSITIONDURATION, "Transition"),
      localStorageAccess
    ),
  ANIMATIONEND =
    checkStorageValue(tnsStorage["tAE"]) ||
    setLocalStorage(
      "tAE",
      getEndProperty(ANIMATIONDURATION, "Animation"),
      localStorageAccess
    );

// reset SUBPIXEL for IE8
if (!CSSMQ) {
  SUBPIXEL = false;
}

export var tns = function(options) {
  options = extend(
    {
      container: ".slider",
      mode: "carousel",
      axis: "horizontal",
      items: 1,
      gutter: 0,
      edgePadding: 0,
      fixedWidth: false,
      fixedWidthViewportWidth: false,
      slideBy: 1,
      controls: true,
      controlsText: ["prev", "next"],
      controlsContainer: false,
      prevButton: false,
      nextButton: false,
      nav: true,
      navContainer: false,
      navAsThumbnails: false,
      arrowKeys: false,
      speed: 300,
      autoplay: false,
      autoplayTimeout: 5000,
      autoplayDirection: "forward",
      autoplayText: ["start", "stop"],
      autoplayHoverPause: false,
      autoplayButton: false,
      autoplayButtonOutput: true,
      autoplayResetOnVisibility: true,
      // animateIn: 'tns-fadeIn',
      // animateOut: 'tns-fadeOut',
      // animateNormal: 'tns-normal',
      // animateDelay: false,
      loop: true,
      rewind: false,
      autoHeight: false,
      responsive: false,
      lazyload: false,
      touch: true,
      mouseDrag: false,
      swipeAngle: 15,
      nested: false,
      freezable: true,
      // startIndex: 0,
      onInit: false
    },
    options || {}
  );

  // get element nodes from selectors
  var supportConsoleWarn =
    win.console && typeof win.console.warn === "function";
  var list = [
    "container",
    "controlsContainer",
    "prevButton",
    "nextButton",
    "navContainer",
    "autoplayButton"
  ];
  for (var i = list.length; i--; ) {
    var item = list[i];
    if (typeof options[item] === "string") {
      var el = doc.querySelector(options[item]);

      if (el && el.nodeName) {
        options[item] = el;
      } else {
        if (supportConsoleWarn) {
          console.warn("Can't find", options[item]);
        }
        return;
      }
    }
  }

  // make sure at least 1 slide
  if (options.container.children && options.container.children.length < 1) {
    if (supportConsoleWarn) {
      console.warn("No slides found in", options.container);
    }
    return;
  }

  // update responsive
  // from: {
  //    300: 2,
  //    800: {
  //      loop: false
  //    }
  // }
  // to: {
  //    300: {
  //      items: 2
  //    },
  //    800: {
  //      loop: false
  //    }
  // }
  if (options.responsive) {
    var resTem = {},
      res = options.responsive;
    for (var key in res) {
      var val = res[key];
      resTem[key] = typeof val === "number" ? { items: val } : val;
    }

    options.responsive = resTem;
    resTem = null;

    // apply responsive[0] to options and remove it
    if (0 in options.responsive) {
      options = extend(options, options.responsive[0]);
      delete options.responsive[0];
    }
  }

  // === define and set variables ===
  var carousel = options.mode === "carousel" ? true : false;

  if (!carousel) {
    options.axis = "horizontal";
    // options.rewind = false;
    // options.loop = true;
    options.edgePadding = false;

    var animateIn = "tns-fadeIn",
      animateOut = "tns-fadeOut",
      animateDelay = false,
      animateNormal = options.animateNormal || "tns-normal";

    if (TRANSITIONEND && ANIMATIONEND) {
      animateIn = options.animateIn || animateIn;
      animateOut = options.animateOut || animateOut;
      animateDelay = options.animateDelay || animateDelay;
    }
  }

  var horizontal = options.axis === "horizontal" ? true : false,
    outerWrapper = doc.createElement("div"),
    innerWrapper = doc.createElement("div"),
    container = options.container,
    containerParent = container.parentNode,
    slideItems = container.children,
    slideCount = slideItems.length,
    vpInner,
    responsive = options.responsive,
    responsiveItems = [],
    breakpoints = false,
    breakpointZone = 0,
    windowWidth = getWindowWidth(),
    isOn;

  if (options.fixedWidth) {
    var vpOuter = getViewportWidth(containerParent);
  }

  if (responsive) {
    breakpoints = Object.keys(responsive)
      .map(function(x) {
        return parseInt(x);
      })
      .sort(function(a, b) {
        return a - b;
      });

    // get all responsive items
    breakpoints.forEach(function(bp) {
      responsiveItems = responsiveItems.concat(Object.keys(responsive[bp]));
    });

    // remove duplicated items
    var arr = [];
    responsiveItems.forEach(function(item) {
      if (arr.indexOf(item) < 0) {
        arr.push(item);
      }
    });
    responsiveItems = arr;

    setBreakpointZone();
  }

  var items = getOption("items"),
    slideBy = getOption("slideBy") === "page" ? items : getOption("slideBy"),
    nested = options.nested,
    gutter = getOption("gutter"),
    edgePadding = getOption("edgePadding"),
    fixedWidth = getOption("fixedWidth"),
    fixedWidthViewportWidth = options.fixedWidthViewportWidth,
    arrowKeys = getOption("arrowKeys"),
    speed = getOption("speed"),
    rewind = options.rewind,
    loop = rewind ? false : options.loop,
    autoHeight = getOption("autoHeight"),
    sheet = createStyleSheet(),
    lazyload = options.lazyload,
    slideOffsetTops, // collection of slide offset tops
    slideItemsOut = [],
    hasEdgePadding = checkOption("edgePadding"),
    cloneCount = loop ? getCloneCountForLoop() : 0,
    slideCountNew = !carousel
      ? slideCount + cloneCount
      : slideCount + cloneCount * 2,
    hasRightDeadZone = fixedWidth && !loop && !edgePadding ? true : false,
    updateIndexBeforeTransform = !carousel || !loop ? true : false,
    // transform
    transformAttr = horizontal ? "left" : "top",
    transformPrefix = "",
    transformPostfix = "",
    // index
    startIndex = getOption("startIndex"),
    index = startIndex
      ? updateStartIndex(startIndex)
      : !carousel
        ? 0
        : cloneCount,
    indexCached = index,
    indexMin = 0,
    indexMax = getIndexMax(),
    // resize
    resizeTimer,
    swipeAngle = options.swipeAngle,
    moveDirectionExpected = swipeAngle ? "?" : true,
    running = false,
    onInit = options.onInit,
    events = new Events(),
    // id, class
    containerIdCached = container.id,
    classContainer = " tns-slider tns-" + options.mode,
    slideId = container.id || getSlideId(),
    disable = getOption("disable"),
    freezable = options.freezable,
    freeze = disable ? true : freezable ? slideCount <= items : false,
    frozen,
    importantStr = nested === "inner" ? " !important" : "",
    controlsEvents = {
      click: onControlsClick,
      keydown: onControlsKeydown
    },
    navEvents = {
      click: onNavClick,
      keydown: onNavKeydown
    },
    hoverEvents = {
      mouseover: mouseoverPause,
      mouseout: mouseoutRestart
    },
    visibilityEvent = { visibilitychange: onVisibilityChange },
    docmentKeydownEvent = { keydown: onDocumentKeydown },
    touchEvents = {
      touchstart: onPanStart,
      touchmove: onPanMove,
      touchend: onPanEnd,
      touchcancel: onPanEnd
    },
    dragEvents = {
      mousedown: onPanStart,
      mousemove: onPanMove,
      mouseup: onPanEnd,
      mouseleave: onPanEnd
    },
    hasControls = checkOption("controls"),
    hasNav = checkOption("nav"),
    navAsThumbnails = options.navAsThumbnails,
    hasAutoplay = checkOption("autoplay"),
    hasTouch = checkOption("touch"),
    hasMouseDrag = checkOption("mouseDrag"),
    slideActiveClass = "tns-slide-active",
    imgCompleteClass = "tns-complete",
    imgEvents = {
      load: imgLoadedOrError,
      error: imgLoadedOrError
    },
    imgsComplete;

  // controls
  if (hasControls) {
    var controls = getOption("controls"),
      controlsText = getOption("controlsText"),
      controlsContainer = options.controlsContainer,
      prevButton = options.prevButton,
      nextButton = options.nextButton,
      prevIsButton,
      nextIsButton;
  }

  // nav
  if (hasNav) {
    var nav = getOption("nav"),
      navContainer = options.navContainer,
      navItems,
      visibleNavIndexes = [],
      visibleNavIndexesCached = visibleNavIndexes,
      navClicked = -1,
      navCurrentIndex = getAbsIndex(),
      navCurrentIndexCached = navCurrentIndex,
      navActiveClass = "tns-nav-active";
  }

  // autoplay
  if (hasAutoplay) {
    var autoplay = getOption("autoplay"),
      autoplayTimeout = getOption("autoplayTimeout"),
      autoplayDirection = options.autoplayDirection === "forward" ? 1 : -1,
      autoplayText = getOption("autoplayText"),
      autoplayHoverPause = getOption("autoplayHoverPause"),
      autoplayButton = options.autoplayButton,
      autoplayResetOnVisibility = getOption("autoplayResetOnVisibility"),
      autoplayHtmlStrings = [
        "<span class='tns-visually-hidden'>",
        " animation</span>"
      ],
      autoplayTimer,
      animating,
      autoplayHoverPaused,
      autoplayUserPaused,
      autoplayVisibilityPaused;
  }

  if (hasTouch || hasMouseDrag) {
    var initPosition = {},
      lastPosition = {},
      translateInit,
      disX,
      disY,
      panStart = false,
      rafIndex = 0,
      getDist = horizontal
        ? function(a, b) {
            return a.x - b.x;
          }
        : function(a, b) {
            return a.y - b.y;
          };
  }

  // touch
  if (hasTouch) {
    var touch = getOption("touch");
  }

  // mouse drag
  if (hasMouseDrag) {
    var mouseDrag = getOption("mouseDrag");
  }

  // disable slider when slidecount <= items
  if (freeze) {
    controls = nav = touch = mouseDrag = arrowKeys = autoplay = autoplayHoverPause = autoplayResetOnVisibility = false;
  }

  if (TRANSFORM) {
    transformAttr = TRANSFORM;
    transformPrefix = "translate";
    transformPrefix += horizontal ? "X(" : "Y(";
    transformPostfix = ")";
  }

  // === COMMON FUNCTIONS === //
  function getIndexMax() {
    return carousel || loop ? slideCountNew - items : slideCountNew - 1;
  }

  function updateStartIndex(indexTem) {
    indexTem = indexTem % slideCount;
    if (indexTem < 0) {
      indexTem += slideCount;
    }
    indexTem = Math.min(indexTem, slideCountNew - items);
    return indexTem;
  }

  function getAbsIndex(i) {
    if (i === undefined) {
      i = index;
    }

    if (carousel) {
      while (i < cloneCount) {
        i += slideCount;
      }
      i -= cloneCount;
    }

    return i ? i % slideCount : i;
  }

  function getItemsMax() {
    if (fixedWidth && !fixedWidthViewportWidth) {
      return slideCount - 1;
    } else {
      var str = fixedWidth ? "fixedWidth" : "items",
        isFW = fixedWidth,
        arr = [];

      if (isFW || options[str] < slideCount) {
        arr.push(options[str]);
      }

      if (breakpoints && responsiveItems.indexOf(str) >= 0) {
        breakpoints.forEach(function(bp) {
          var tem = responsive[bp][str];
          if (tem && (isFW || tem < slideCount)) {
            arr.push(tem);
          }
        });
      }

      if (!arr.length) {
        arr.push(0);
      }

      return isFW
        ? Math.ceil(fixedWidthViewportWidth / Math.min.apply(null, arr))
        : Math.max.apply(null, arr);
    }
  }

  function getCloneCountForLoop() {
    var itemsMax = getItemsMax(),
      result = carousel
        ? Math.ceil((itemsMax * 5 - slideCount) / 2)
        : itemsMax * 4 - slideCount;
    result = Math.max(itemsMax, result);

    return hasEdgePadding ? result + 1 : result;
  }

  function getWindowWidth() {
    return (
      win.innerWidth || doc.documentElement.clientWidth || doc.body.clientWidth
    );
  }

  function getViewportWidth(el) {
    if (el) return el.clientWidth || getViewportWidth(el.parentNode);
  }

  function checkOption(item) {
    var result = options[item];
    if (!result && breakpoints && responsiveItems.indexOf(item) >= 0) {
      breakpoints.forEach(function(bp) {
        if (responsive[bp][item]) {
          result = true;
        }
      });
    }
    return result;
  }

  function getOption(item, viewport) {
    viewport = viewport ? viewport : windowWidth;

    var obj = {
        slideBy: "page",
        edgePadding: false
      },
      result;

    if (!carousel && item in obj) {
      result = obj[item];
    } else {
      if (item === "items" && getOption("fixedWidth")) {
        result = Math.floor(
          vpOuter / (getOption("fixedWidth") + getOption("gutter"))
        );
      } else if (item === "autoHeight" && nested === "outer") {
        result = true;
      } else {
        result = options[item];

        if (breakpoints && responsiveItems.indexOf(item) >= 0) {
          for (var i = 0, len = breakpoints.length; i < len; i++) {
            var bp = breakpoints[i];
            if (viewport >= bp) {
              if (item in responsive[bp]) {
                result = responsive[bp][item];
              }
            } else {
              break;
            }
          }
        }
      }
    }

    if (item === "slideBy" && result === "page") {
      result = getOption("items");
    }

    return result;
  }

  function getSlideMarginLeft(i) {
    var str = CALC
      ? CALC + "(" + i * 100 + "% / " + slideCountNew + ")"
      : (i * 100) / slideCountNew + "%";
    return str;
  }

  function getInnerWrapperStyles(
    edgePaddingTem,
    gutterTem,
    fixedWidthTem,
    speedTem
  ) {
    var str = "";

    if (edgePaddingTem) {
      var gap = edgePaddingTem;
      if (gutterTem) {
        gap += gutterTem;
      }
      if (fixedWidthTem) {
        str =
          "margin: 0px " +
          ((vpOuter % (fixedWidthTem + gutterTem)) + gutterTem) / 2 +
          "px";
      } else {
        str = horizontal
          ? "margin: 0 " + edgePaddingTem + "px 0 " + gap + "px;"
          : "padding: " + gap + "px 0 " + edgePaddingTem + "px 0;";
      }
    } else if (gutterTem && !fixedWidthTem) {
      var gutterTemUnit = "-" + gutterTem + "px",
        dir = horizontal ? gutterTemUnit + " 0 0" : "0 " + gutterTemUnit + " 0";
      str = "margin: 0 " + dir + ";";
    }

    if (TRANSITIONDURATION && speedTem) {
      str += getTrsnsitionDurationStyle(speedTem);
    }

    return str;
  }

  function getContainerWidth(fixedWidthTem, gutterTem, itemsTem) {
    var str;

    if (fixedWidthTem) {
      str = (fixedWidthTem + gutterTem) * slideCountNew + "px";
    } else {
      str = CALC
        ? CALC + "(" + slideCountNew * 100 + "% / " + itemsTem + ")"
        : (slideCountNew * 100) / itemsTem + "%";
    }

    return str;
  }

  function getSlideWidthStyle(fixedWidthTem, gutterTem, itemsTem) {
    var str = "";

    if (horizontal) {
      str = "width:";
      if (fixedWidthTem) {
        str += fixedWidthTem + gutterTem + "px";
      } else {
        var dividend = carousel ? slideCountNew : itemsTem;
        str += CALC ? CALC + "(100% / " + dividend + ")" : 100 / dividend + "%";
      }
      str += importantStr + ";";
    }

    return str;
  }

  function getSlideGutterStyle(gutterTem) {
    var str = "";

    // gutter maybe interger || 0
    // so can't use 'if (gutter)'
    if (gutterTem !== false) {
      var prop = horizontal ? "padding-" : "margin-",
        dir = horizontal ? "right" : "bottom";
      str = prop + dir + ": " + gutterTem + "px;";
    }

    return str;
  }

  function getCSSPrefix(name, num) {
    var prefix = name.substring(0, name.length - num).toLowerCase();
    if (prefix) {
      prefix = "-" + prefix + "-";
    }

    return prefix;
  }

  function getTrsnsitionDurationStyle(speed) {
    return (
      getCSSPrefix(TRANSITIONDURATION, 18) +
      "transition-duration:" +
      speed / 1000 +
      "s;"
    );
  }

  function getAnimationDurationStyle(speed) {
    return (
      getCSSPrefix(ANIMATIONDURATION, 17) +
      "animation-duration:" +
      speed / 1000 +
      "s;"
    );
  }

  (function sliderInit() {
    // First thing first, wrap container with 'outerWrapper > innerWrapper',
    // to get the correct view width
    outerWrapper.appendChild(innerWrapper);
    containerParent.insertBefore(outerWrapper, container);
    innerWrapper.appendChild(container);
    vpInner = getViewportWidth(innerWrapper);

    var classOuter = "tns-outer",
      classInner = "tns-inner",
      hasGutter = checkOption("gutter");

    if (carousel) {
      if (horizontal) {
        if (checkOption("edgePadding") || (hasGutter && !options.fixedWidth)) {
          classOuter += " tns-ovh";
        } else {
          classInner += " tns-ovh";
        }
      } else {
        classInner += " tns-ovh";
      }
    } else if (hasGutter) {
      classOuter += " tns-ovh";
    }

    outerWrapper.className = classOuter;
    innerWrapper.className = classInner;
    innerWrapper.id = slideId + "-iw";
    if (autoHeight) {
      innerWrapper.className += " tns-ah";
      innerWrapper.style[TRANSITIONDURATION] = speed / 1000 + "s";
    }

    // set container properties
    if (container.id === "") {
      container.id = slideId;
    }
    classContainer += SUBPIXEL ? " tns-subpixel" : " tns-no-subpixel";
    classContainer += CALC ? " tns-calc" : " tns-no-calc";
    if (carousel) {
      classContainer += " tns-" + options.axis;
    }
    container.className += classContainer;
    // add event
    if (carousel && TRANSITIONEND) {
      var eve = {};
      eve[TRANSITIONEND] = onTransitionEnd;
      addEvents(container, eve);
    }

    // delete datas after init
    classOuter = classInner = null;

    // add id, class, aria attributes
    // before clone slides
    for (var x = 0; x < slideCount; x++) {
      var item = slideItems[x];
      if (!item.id) {
        item.id = slideId + "-item" + x;
      }
      addClass(item, "tns-item");
      if (!carousel && animateNormal) {
        addClass(item, animateNormal);
      }
      setAttrs(item, {
        "aria-hidden": "true",
        tabindex: "-1"
      });
    }

    // clone slides
    if (loop || edgePadding) {
      var fragmentBefore = doc.createDocumentFragment(),
        fragmentAfter = doc.createDocumentFragment();

      for (var j = cloneCount; j--; ) {
        var num = j % slideCount,
          cloneFirst = slideItems[num].cloneNode(true);
        removeAttrs(cloneFirst, "id");
        fragmentAfter.insertBefore(cloneFirst, fragmentAfter.firstChild);

        if (carousel) {
          var cloneLast = slideItems[slideCount - 1 - num].cloneNode(true);
          removeAttrs(cloneLast, "id");
          fragmentBefore.appendChild(cloneLast);
        }
      }

      container.insertBefore(fragmentBefore, container.firstChild);
      container.appendChild(fragmentAfter);
      slideItems = container.children;
    }

    // add image events
    if (checkOption("autoHeight") || !carousel) {
      var imgs = container.querySelectorAll("img");

      // check all image complete status
      // add complete class if true
      forEachNodeList(imgs, function(img) {
        addEvents(img, imgEvents);

        var src = img.src;
        img.src =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        img.src = src;
      });

      // set imgsComplete to true
      // when all images are compulete (loaded or error)
      raf(function() {
        checkImagesLoaded(arrayFromNodeList(imgs), function() {
          imgsComplete = true;
        });
      });
    }

    // activate visible slides
    // add aria attrs
    // set animation classes and left value for gallery slider
    // use slide count when slides are fewer than items
    for (var i = index, l = index + Math.min(slideCount, items); i < l; i++) {
      var item = slideItems[i];
      setAttrs(item, { "aria-hidden": "false" });
      removeAttrs(item, ["tabindex"]);
      addClass(item, slideActiveClass);

      if (!carousel) {
        item.style.left = ((i - index) * 100) / items + "%";
        addClass(item, animateIn);
        removeClass(item, animateNormal);
      }
    }

    if (carousel && horizontal) {
      // set font-size rules
      // for modern browsers
      if (SUBPIXEL) {
        // set slides font-size first
        addCSSRule(
          sheet,
          "#" + slideId + " > .tns-item",
          "font-size:" + win.getComputedStyle(slideItems[0]).fontSize + ";",
          getCssRulesLength(sheet)
        );
        addCSSRule(
          sheet,
          "#" + slideId,
          "font-size:0;",
          getCssRulesLength(sheet)
        );

        // slide left margin
        // for IE8 & webkit browsers (no subpixel)
      } else {
        forEachNodeList(slideItems, function(slide, i) {
          slide.style.marginLeft = getSlideMarginLeft(i);
        });
      }
    }

    // all browsers which support CSS transitions support CSS media queries
    if (CSSMQ) {
      // inner wrapper styles
      var str = getInnerWrapperStyles(
        options.edgePadding,
        options.gutter,
        options.fixedWidth,
        options.speed
      );
      addCSSRule(sheet, "#" + slideId + "-iw", str, getCssRulesLength(sheet));

      // container styles
      if (carousel) {
        str = horizontal
          ? "width:" +
            getContainerWidth(
              options.fixedWidth,
              options.gutter,
              options.items
            ) +
            ";"
          : "";
        if (TRANSITIONDURATION) {
          str += getTrsnsitionDurationStyle(speed);
        }
        addCSSRule(sheet, "#" + slideId, str, getCssRulesLength(sheet));
      }

      // slide styles
      if (horizontal || options.gutter) {
        str =
          getSlideWidthStyle(
            options.fixedWidth,
            options.gutter,
            options.items
          ) + getSlideGutterStyle(options.gutter);
        // set gallery items transition-duration
        if (!carousel) {
          if (TRANSITIONDURATION) {
            str += getTrsnsitionDurationStyle(speed);
          }
          if (ANIMATIONDURATION) {
            str += getAnimationDurationStyle(speed);
          }
        }
        addCSSRule(
          sheet,
          "#" + slideId + " > .tns-item",
          str,
          getCssRulesLength(sheet)
        );
      }

      // non CSS mediaqueries: IE8
      // ## update inner wrapper, container, slides if needed
      // set inline styles for inner wrapper & container
      // insert stylesheet (one line) for slides only (since slides are many)
    } else {
      // inner wrapper styles
      innerWrapper.style.cssText = getInnerWrapperStyles(
        edgePadding,
        gutter,
        fixedWidth
      );

      // container styles
      if (carousel && horizontal) {
        container.style.width = getContainerWidth(fixedWidth, gutter, items);
      }

      // slide styles
      if (horizontal || gutter) {
        var str =
          getSlideWidthStyle(fixedWidth, gutter, items) +
          getSlideGutterStyle(gutter);
        // append to the last line
        addCSSRule(
          sheet,
          "#" + slideId + " > .tns-item",
          str,
          getCssRulesLength(sheet)
        );
      }
    }

    if (!horizontal && !disable) {
      getSlideOffsetTops();
      updateContentWrapperHeight();
    }

    // media queries
    if (responsive && CSSMQ) {
      breakpoints.forEach(function(bp) {
        var opts = responsive[bp],
          str = "",
          innerWrapperStr = "",
          containerStr = "",
          slideStr = "",
          itemsBP = getOption("items", bp),
          fixedWidthBP = getOption("fixedWidth", bp),
          speedBP = getOption("speed", bp),
          edgePaddingBP = getOption("edgePadding", bp),
          gutterBP = getOption("gutter", bp);

        // inner wrapper string
        if ("edgePadding" in opts || "gutter" in opts) {
          innerWrapperStr =
            "#" +
            slideId +
            "-iw{" +
            getInnerWrapperStyles(
              edgePaddingBP,
              gutterBP,
              fixedWidthBP,
              speedBP
            ) +
            "}";
        }

        // container string
        if (
          carousel &&
          horizontal &&
          ("fixedWidth" in opts || "gutter" in opts || "items" in opts)
        ) {
          containerStr =
            "width:" + getContainerWidth(fixedWidthBP, gutterBP, itemsBP) + ";";
        }
        if (TRANSITIONDURATION && "speed" in opts) {
          containerStr += getTrsnsitionDurationStyle(speedBP);
        }
        if (containerStr) {
          containerStr = "#" + slideId + "{" + containerStr + "}";
        }

        // slide string
        if (
          "fixedWidth" in opts ||
          (checkOption("fixedWidth") && "gutter" in opts) ||
          (!carousel && "items" in opts)
        ) {
          slideStr += getSlideWidthStyle(fixedWidthBP, gutterBP, itemsBP);
        }
        if ("gutter" in opts) {
          slideStr += getSlideGutterStyle(gutterBP);
        }
        // set gallery items transition-duration
        if (!carousel && "speed" in opts) {
          if (TRANSITIONDURATION) {
            slideStr += getTrsnsitionDurationStyle(speedBP);
          }
          if (ANIMATIONDURATION) {
            slideStr += getAnimationDurationStyle(speedBP);
          }
        }
        if (slideStr) {
          slideStr = "#" + slideId + " > .tns-item{" + slideStr + "}";
        }

        // add up
        str = innerWrapperStr + containerStr + slideStr;

        if (str) {
          sheet.insertRule(
            "@media (min-width: " + bp / 16 + "em) {" + str + "}",
            sheet.cssRules.length
          );
        }
      });
    }

    // set container transform property
    if (carousel && !disable) {
      doContainerTransformSilent();
    }

    // == msInit ==
    // for IE10
    if (navigator.msMaxTouchPoints) {
      addClass(container, "ms-touch");
      addEvents(container, { scroll: ie10Scroll });
      setSnapInterval();
    }

    // == navInit ==
    if (hasNav) {
      var initIndex = !carousel ? 0 : cloneCount;
      // customized nav
      // will not hide the navs in case they're thumbnails
      if (navContainer) {
        setAttrs(navContainer, { "aria-label": "Carousel Pagination" });
        navItems = navContainer.children;
        forEachNodeList(navItems, function(item, i) {
          setAttrs(item, {
            "data-nav": i,
            tabindex: "-1",
            "aria-selected": "false",
            "aria-controls": slideItems[initIndex + i].id
          });
        });

        // generated nav
      } else {
        var navHtml = "",
          hiddenStr = navAsThumbnails ? "" : "hidden";
        for (var i = 0; i < slideCount; i++) {
          // hide nav items by default
          navHtml +=
            '<button data-nav="' +
            i +
            '" tabindex="-1" aria-selected="false" aria-controls="' +
            slideItems[initIndex + i].id +
            '" ' +
            hiddenStr +
            ' type="button"></button>';
        }
        navHtml =
          '<div class="tns-nav" aria-label="Carousel Pagination">' +
          navHtml +
          "</div>";
        outerWrapper.insertAdjacentHTML("afterbegin", navHtml);

        navContainer = outerWrapper.querySelector(".tns-nav");
        navItems = navContainer.children;
      }

      updateNavVisibility();

      // add transition
      if (TRANSITIONDURATION) {
        var prefix = TRANSITIONDURATION.substring(
            0,
            TRANSITIONDURATION.length - 18
          ).toLowerCase(),
          str = "transition: all " + speed / 1000 + "s";

        if (prefix) {
          str = "-" + prefix + "-" + str;
        }

        addCSSRule(
          sheet,
          "[aria-controls^=" + slideId + "-item]",
          str,
          getCssRulesLength(sheet)
        );
      }

      setAttrs(navItems[navCurrentIndex], {
        tabindex: "0",
        "aria-selected": "true"
      });
      addClass(navItems[navCurrentIndex], navActiveClass);

      // add events
      addEvents(navContainer, navEvents);

      if (!nav) {
        hideElement(navContainer);
      }
    }

    // == autoplayInit ==
    if (hasAutoplay) {
      var txt = autoplay ? "stop" : "start";
      if (autoplayButton) {
        setAttrs(autoplayButton, { "data-action": txt });
      } else if (options.autoplayButtonOutput) {
        innerWrapper.insertAdjacentHTML(
          "beforebegin",
          '<button data-action="' +
            txt +
            '" type="button">' +
            autoplayHtmlStrings[0] +
            txt +
            autoplayHtmlStrings[1] +
            autoplayText[0] +
            "</button>"
        );
        autoplayButton = outerWrapper.querySelector("[data-action]");
      }

      // add event
      if (autoplayButton) {
        addEvents(autoplayButton, { click: toggleAutoplay });
      }

      if (!autoplay) {
        if (autoplayButton) {
          hideElement(autoplayButton);
        }
      } else {
        startAutoplay();
        if (autoplayHoverPause) {
          addEvents(container, hoverEvents);
        }
        if (autoplayResetOnVisibility) {
          addEvents(container, visibilityEvent);
        }
      }
    }

    // == controlsInit ==
    if (hasControls) {
      if (controlsContainer || (prevButton && nextButton)) {
        if (controlsContainer) {
          prevButton = controlsContainer.children[0];
          nextButton = controlsContainer.children[1];
          setAttrs(controlsContainer, {
            "aria-label": "Carousel Navigation",
            tabindex: "0"
          });
          setAttrs(controlsContainer.children, {
            "aria-controls": slideId,
            tabindex: "-1"
          });
        }

        setAttrs(prevButton, { "data-controls": "prev" });
        setAttrs(nextButton, { "data-controls": "next" });
      } else {
        outerWrapper.insertAdjacentHTML(
          "afterbegin",
          '<div class="tns-controls" aria-label="Carousel Navigation" tabindex="0"><button data-controls="prev" tabindex="-1" aria-controls="' +
            slideId +
            '" type="button">' +
            controlsText[0] +
            '</button><button data-controls="next" tabindex="-1" aria-controls="' +
            slideId +
            '" type="button">' +
            controlsText[1] +
            "</button></div>"
        );

        controlsContainer = outerWrapper.querySelector(".tns-controls");
        prevButton = controlsContainer.children[0];
        nextButton = controlsContainer.children[1];
      }

      prevIsButton = isButton(prevButton);
      nextIsButton = isButton(nextButton);

      updateControlsStatus();

      // add events
      if (controlsContainer) {
        addEvents(controlsContainer, controlsEvents);
      } else {
        addEvents(prevButton, controlsEvents);
        addEvents(nextButton, controlsEvents);
      }

      if (!controls) {
        hideElement(controlsContainer);
      }
    }

    // if (!navigator.msMaxTouchPoints) {
    if (carousel) {
      if (touch) {
        addEvents(container, touchEvents);
      }
      if (mouseDrag) {
        addEvents(container, dragEvents);
      }
    }
    // }
    if (arrowKeys) {
      addEvents(doc, docmentKeydownEvent);
    }

    if (nested === "inner") {
      events.on("outerResized", function() {
        resizeTasks();
        events.emit("innerLoaded", info());
      });
    } else {
      addEvents(win, { resize: onResize });
    }

    if (nested === "outer") {
      events.on("innerLoaded", runAutoHeight);
    } else if ((autoHeight || !carousel) && !disable) {
      runAutoHeight();
    }

    lazyLoad();
    toggleSlideDisplayAndEdgePadding();
    updateFixedWidthInnerWrapperStyle();

    events.on("indexChanged", additionalUpdates);

    if (typeof onInit === "function") {
      onInit(info());
    }
    if (nested === "inner") {
      events.emit("innerLoaded", info());
    }

    if (disable) {
      disableSlider(true);
    }

    isOn = true;
  })();

  // === ON RESIZE ===
  function onResize(e) {
    raf(function() {
      resizeTasks(getEvent(e));
    });
  }

  function resizeTasks(e) {
    if (!isOn) {
      return;
    }

    windowWidth = getWindowWidth();
    if (nested === "outer") {
      events.emit("outerResized", info(e));
    }

    var breakpointZoneTem = breakpointZone,
      indexTem = index,
      itemsTem = items,
      freezeTem = freeze,
      needContainerTransform = false;

    if (fixedWidth) {
      vpOuter = getViewportWidth(outerWrapper);
    }
    vpInner = getViewportWidth(innerWrapper);
    if (breakpoints) {
      setBreakpointZone();
    }

    // things do when breakpoint zone change
    if (breakpointZoneTem !== breakpointZone || fixedWidth) {
      var slideByTem = slideBy,
        arrowKeysTem = arrowKeys,
        autoHeightTem = autoHeight,
        fixedWidthTem = fixedWidth,
        edgePaddingTem = edgePadding,
        gutterTem = gutter,
        disableTem = disable;

      // update variables
      items = getOption("items");
      slideBy = getOption("slideBy");
      disable = getOption("disable");
      freeze = disable ? true : freezable ? slideCount <= items : false;

      if (items !== itemsTem) {
        indexMax = getIndexMax();
        // check index before transform in case
        // slider reach the right edge then items become bigger
        updateIndex();
      }

      if (disable !== disableTem) {
        disableSlider(disable);
      }

      if (freeze !== freezeTem) {
        // reset index to initial status
        if (freeze) {
          index = !carousel ? 0 : cloneCount;
        }

        toggleSlideDisplayAndEdgePadding();
      }

      if (breakpointZoneTem !== breakpointZone) {
        speed = getOption("speed");
        edgePadding = getOption("edgePadding");
        gutter = getOption("gutter");

        fixedWidth = getOption("fixedWidth");
        if (!disable && fixedWidth !== fixedWidthTem) {
          needContainerTransform = true;
        }

        autoHeight = getOption("autoHeight");
        if (autoHeight !== autoHeightTem) {
          if (!autoHeight) {
            innerWrapper.style.height = "";
          }
        }
      }

      arrowKeys = freeze ? false : getOption("arrowKeys");
      if (arrowKeys !== arrowKeysTem) {
        arrowKeys
          ? addEvents(doc, docmentKeydownEvent)
          : removeEvents(doc, docmentKeydownEvent);
      }

      if (hasControls) {
        var controlsTem = controls,
          controlsTextTem = controlsText;
        controls = freeze ? false : getOption("controls");
        controlsText = getOption("controlsText");

        if (controls !== controlsTem) {
          controls
            ? showElement(controlsContainer)
            : hideElement(controlsContainer);
        }
        if (controlsText !== controlsTextTem) {
          prevButton.innerHTML = controlsText[0];
          nextButton.innerHTML = controlsText[1];
        }
      }
      if (hasNav) {
        var navTem = nav;
        nav = freeze ? false : getOption("nav");

        if (nav !== navTem) {
          if (nav) {
            showElement(navContainer);
            updateNavVisibility();
          } else {
            hideElement(navContainer);
          }
        }
      }
      if (hasTouch) {
        var touchTem = touch;
        touch = freeze ? false : getOption("touch");

        if (touch !== touchTem && carousel) {
          touch
            ? addEvents(container, touchEvents)
            : removeEvents(container, touchEvents);
        }
      }
      if (hasMouseDrag) {
        var mouseDragTem = mouseDrag;
        mouseDrag = freeze ? false : getOption("mouseDrag");

        if (mouseDrag !== mouseDragTem && carousel) {
          mouseDrag
            ? addEvents(container, dragEvents)
            : removeEvents(container, dragEvents);
        }
      }
      if (hasAutoplay) {
        var autoplayTem = autoplay,
          autoplayHoverPauseTem = autoplayHoverPause,
          autoplayResetOnVisibilityTem = autoplayResetOnVisibility,
          autoplayTextTem = autoplayText;

        if (freeze) {
          autoplay = autoplayHoverPause = autoplayResetOnVisibility = false;
        } else {
          autoplay = getOption("autoplay");

          if (autoplay) {
            autoplayHoverPause = getOption("autoplayHoverPause");
            autoplayResetOnVisibility = getOption("autoplayResetOnVisibility");
          } else {
            autoplayHoverPause = autoplayResetOnVisibility = false;
          }
        }
        autoplayText = getOption("autoplayText");
        autoplayTimeout = getOption("autoplayTimeout");

        if (autoplay !== autoplayTem) {
          if (autoplay) {
            if (autoplayButton) {
              showElement(autoplayButton);
            }
            if (!animating && !autoplayUserPaused) {
              startAutoplay();
            }
          } else {
            if (autoplayButton) {
              hideElement(autoplayButton);
            }
            if (animating) {
              stopAutoplay();
            }
          }
        }
        if (autoplayHoverPause !== autoplayHoverPauseTem) {
          autoplayHoverPause
            ? addEvents(container, hoverEvents)
            : removeEvents(container, hoverEvents);
        }
        if (autoplayResetOnVisibility !== autoplayResetOnVisibilityTem) {
          autoplayResetOnVisibility
            ? addEvents(doc, visibilityEvent)
            : removeEvents(doc, visibilityEvent);
        }
        if (autoplayButton && autoplayText !== autoplayTextTem) {
          var i = autoplay ? 1 : 0,
            html = autoplayButton.innerHTML,
            len = html.length - autoplayTextTem[i].length;
          if (html.substring(len) === autoplayTextTem[i]) {
            autoplayButton.innerHTML = html.substring(0, len) + autoplayText[i];
          }
        }
      }

      // IE8
      // ## update inner wrapper, container, slides if needed
      // set inline styles for inner wrapper & container
      // insert stylesheet (one line) for slides only (since slides are many)
      if (!CSSMQ) {
        // inner wrapper styles
        if (
          !freeze &&
          (edgePadding !== edgePaddingTem || gutter !== gutterTem)
        ) {
          innerWrapper.style.cssText = getInnerWrapperStyles(
            edgePadding,
            gutter,
            fixedWidth
          );
        }

        // container styles
        if (
          carousel &&
          horizontal &&
          (fixedWidth !== fixedWidthTem ||
            gutter !== gutterTem ||
            items !== itemsTem)
        ) {
          container.style.width = getContainerWidth(fixedWidth, gutter, items);
        }

        // slide styles
        if (
          horizontal &&
          (items !== itemsTem ||
            gutter !== gutterTem ||
            fixedWidth != fixedWidthTem)
        ) {
          var str =
            getSlideWidthStyle(fixedWidth, gutter, items) +
            getSlideGutterStyle(gutter);

          // remove the last line and
          // add new styles
          sheet.removeRule(getCssRulesLength(sheet) - 1);
          addCSSRule(
            sheet,
            "#" + slideId + " > .tns-item",
            str,
            getCssRulesLength(sheet)
          );
        }

        if (!fixedWidth) {
          needContainerTransform = true;
        }
      }

      if (index !== indexTem) {
        events.emit("indexChanged", info());
        needContainerTransform = true;
      }

      if (items !== itemsTem) {
        additionalUpdates();
        updateSlidePosition();

        if (navigator.msMaxTouchPoints) {
          setSnapInterval();
        }
      }
    }

    // things always do regardless of breakpoint zone changing
    if (!horizontal && !disable) {
      getSlideOffsetTops();
      updateContentWrapperHeight();
      needContainerTransform = true;
    }

    if (needContainerTransform) {
      doContainerTransformSilent();
      indexCached = index;
    }

    updateFixedWidthInnerWrapperStyle(true);

    // auto height
    if ((autoHeight || !carousel) && !disable) {
      runAutoHeight();
    }
  }

  // === INITIALIZATION FUNCTIONS === //
  function setBreakpointZone() {
    breakpointZone = 0;
    breakpoints.forEach(function(bp, i) {
      if (windowWidth >= bp) {
        breakpointZone = i + 1;
      }
    });
  }

  function loopNumber(num, min, max) {
    return index >= indexMin && index <= indexMax
      ? index
      : index > indexMax
        ? index - slideCount
        : index + slideCount;
  }

  function cutindexber(index, min, indexMax) {
    return index >= indexMin && index <= indexMax
      ? index
      : index > indexMax
        ? indexMax
        : indexMin;
  }

  // (slideBy, indexMin, indexMax) => index
  var updateIndex = (function() {
    return loop
      ? carousel
        ? // loop + carousel
          function() {
            var leftEdge = indexMin,
              rightEdge = indexMax;

            leftEdge += slideBy;
            rightEdge -= slideBy;

            // adjust edges when edge padding is true
            // or fixed-width slider with extra space on the right side
            if (edgePadding) {
              leftEdge += 1;
              rightEdge -= 1;
            } else if (fixedWidth) {
              var gt = gutter ? gutter : 0;
              if (vpOuter % (fixedWidth + gt) > gt) {
                rightEdge -= 1;
              }
            }

            if (cloneCount) {
              if (index > rightEdge) {
                index -= slideCount;
              } else if (index < leftEdge) {
                index += slideCount;
              }
            }
          }
        : // loop + gallery
          function() {
            if (index > indexMax) {
              while (index >= indexMin + slideCount) {
                index -= slideCount;
              }
            } else if (index < indexMin) {
              while (index <= indexMax - slideCount) {
                index += slideCount;
              }
            }
          }
      : // non-loop
        function() {
          index =
            index >= indexMin && index <= indexMax
              ? index
              : index > indexMax
                ? indexMax
                : indexMin;
        };
  })();

  function toggleSlideDisplayAndEdgePadding() {
    // if (cloneCount) {
    // if (fixedWidth && cloneCount) {
    var str = "tns-transparent";

    if (freeze) {
      if (!frozen) {
        // remove edge padding from inner wrapper
        if (edgePadding) {
          innerWrapper.style.margin = "0px";
        }

        // add class tns-transparent to cloned slides
        if (cloneCount) {
          for (var i = cloneCount; i--; ) {
            if (carousel) {
              addClass(slideItems[i], str);
            }
            addClass(slideItems[slideCountNew - i - 1], str);
          }
        }

        frozen = true;
      }
    } else if (frozen) {
      // restore edge padding for inner wrapper
      // for mordern browsers
      if (edgePadding && !fixedWidth && CSSMQ) {
        innerWrapper.style.margin = "";
      }

      // remove class tns-transparent to cloned slides
      if (cloneCount) {
        for (var i = cloneCount; i--; ) {
          if (carousel) {
            removeClass(slideItems[i], str);
          }
          removeClass(slideItems[slideCountNew - i - 1], str);
        }
      }

      frozen = false;
    }
    // }
  }

  function updateFixedWidthInnerWrapperStyle(resize) {
    if (fixedWidth && edgePadding) {
      // remove edge padding when freeze or viewport narrower than one slide
      if (freeze || vpOuter <= fixedWidth + gutter) {
        if (innerWrapper.style.margin !== "0px") {
          innerWrapper.style.margin = "0px";
        }
        // update edge padding on resize
      } else if (resize) {
        innerWrapper.style.cssText = getInnerWrapperStyles(
          edgePadding,
          gutter,
          fixedWidth
        );
      }
    }
  }

  function disableSlider(disable) {
    var len = slideItems.length;

    if (disable) {
      sheet.disabled = true;
      container.className = container.className.replace(
        classContainer.substring(1),
        ""
      );
      removeElementStyles(container);
      if (loop) {
        for (var j = cloneCount; j--; ) {
          if (carousel) {
            hideElement(slideItems[j]);
          }
          hideElement(slideItems[len - j - 1]);
        }
      }

      // vertical slider
      if (!horizontal || !carousel) {
        removeElementStyles(innerWrapper);
      }

      // gallery
      if (!carousel) {
        for (var i = index, l = index + slideCount; i < l; i++) {
          var item = slideItems[i];
          removeElementStyles(item);
          removeClass(item, animateIn);
          removeClass(item, animateNormal);
        }
      }
    } else {
      sheet.disabled = false;
      container.className += classContainer;

      // vertical slider: get offsetTops before container transform
      if (!horizontal) {
        getSlideOffsetTops();
      }

      doContainerTransformSilent();
      if (loop) {
        for (var j = cloneCount; j--; ) {
          if (carousel) {
            showElement(slideItems[j]);
          }
          showElement(slideItems[len - j - 1]);
        }
      }

      // gallery
      if (!carousel) {
        for (var i = index, l = index + slideCount; i < l; i++) {
          var item = slideItems[i],
            classN = i < index + items ? animateIn : animateNormal;
          item.style.left = ((i - index) * 100) / items + "%";
          addClass(item, classN);
        }
      }
    }
  }

  // lazyload
  function lazyLoad() {
    if (lazyload && !disable) {
      var i = index,
        len = index + items;

      if (edgePadding) {
        i -= 1;
        len += 1;
      }

      for (; i < len; i++) {
        forEachNodeList(
          slideItems[i].querySelectorAll(".tns-lazy-img"),
          function(img) {
            // stop propagationl transitionend event to container
            var eve = {};
            eve[TRANSITIONEND] = function(e) {
              e.stopPropagation();
            };
            addEvents(img, eve);

            if (!hasClass(img, "loaded")) {
              img.src = getAttr(img, "data-src");
              addClass(img, "loaded");
            }
          }
        );
      }
    }
  }

  function imgLoadedOrError(e) {
    var img = getTarget(e);
    addClass(img, imgCompleteClass);
    removeEvents(img, imgEvents);
  }

  function getImageArray(slideStart, slideRange) {
    var imgs = [];
    for (var i = slideStart, l = slideStart + slideRange; i < l; i++) {
      forEachNodeList(slideItems[i].querySelectorAll("img"), function(img) {
        imgs.push(img);
      });
    }

    return imgs;
  }

  // check if all visible images are loaded
  // and update container height if it's done
  function runAutoHeight() {
    var imgs = autoHeight
      ? getImageArray(index, items)
      : getImageArray(cloneCount, slideCount);

    raf(function() {
      checkImagesLoaded(imgs, updateInnerWrapperHeight);
    });
  }

  function checkImagesLoaded(imgs, cb) {
    // directly execute callback function if all images are complete
    if (imgsComplete) {
      return cb();
    }

    // check selected image classes otherwise
    imgs.forEach(function(img, index) {
      if (hasClass(img, imgCompleteClass)) {
        imgs.splice(index, 1);
      }
    });

    // execute callback function if selected images are all complete
    if (!imgs.length) {
      return cb();
    }

    // otherwise execute this functiona again
    raf(function() {
      checkImagesLoaded(imgs, cb);
    });
  }

  function additionalUpdates() {
    lazyLoad();
    updateSlideStatus();
    updateControlsStatus();
    updateNavVisibility();
    updateNavStatus();
  }

  function getMaxSlideHeight(slideStart, slideRange) {
    var heights = [];
    for (var i = slideStart, l = slideStart + slideRange; i < l; i++) {
      heights.push(slideItems[i].offsetHeight);
    }

    return Math.max.apply(null, heights);
  }

  // update inner wrapper height
  // 1. get the max-height of the visible slides
  // 2. set transitionDuration to speed
  // 3. update inner wrapper height to max-height
  // 4. set transitionDuration to 0s after transition done
  function updateInnerWrapperHeight() {
    var maxHeight = autoHeight
      ? getMaxSlideHeight(index, items)
      : getMaxSlideHeight(cloneCount, slideCount);

    if (innerWrapper.style.height !== maxHeight) {
      innerWrapper.style.height = maxHeight + "px";
    }
  }

  // get the distance from the top edge of the first slide to each slide
  // (init) => slideOffsetTops
  function getSlideOffsetTops() {
    slideOffsetTops = [0];
    var topFirst = slideItems[0].getBoundingClientRect().top,
      attr;
    for (var i = 1; i < slideCountNew; i++) {
      attr = slideItems[i].getBoundingClientRect().top;
      slideOffsetTops.push(attr - topFirst);
    }
  }

  // set snapInterval (for IE10)
  function setSnapInterval() {
    outerWrapper.style.msScrollSnapPointsX =
      "snapInterval(0%, " + 100 / items + "%)";
  }

  // update slide
  function updateSlideStatus() {
    var l = index + Math.min(slideCount, items);
    for (var i = slideCountNew; i--; ) {
      var item = slideItems[i];

      // visible slides
      if (i >= index && i < l) {
        if (hasAttr(item, "tabindex")) {
          setAttrs(item, { "aria-hidden": "false" });
          removeAttrs(item, ["tabindex"]);
          addClass(item, slideActiveClass);
        }
        // hidden slides
      } else {
        if (!hasAttr(item, "tabindex")) {
          setAttrs(item, {
            "aria-hidden": "true",
            tabindex: "-1"
          });
        }
        if (hasClass(item, slideActiveClass)) {
          removeClass(item, slideActiveClass);
        }
      }
    }
  }

  // gallery: update slide position
  function updateSlidePosition() {
    if (!carousel) {
      var l = index + Math.min(slideCount, items);
      for (var i = slideCountNew; i--; ) {
        var item = slideItems[i];

        if (i >= index && i < l) {
          // add transitions to visible slides when adjusting their positions
          addClass(item, "tns-moving");

          item.style.left = ((i - index) * 100) / items + "%";
          addClass(item, animateIn);
          removeClass(item, animateNormal);
        } else if (item.style.left) {
          item.style.left = "";
          addClass(item, animateNormal);
          removeClass(item, animateIn);
        }

        // remove outlet animation
        removeClass(item, animateOut);
      }

      // removing '.tns-moving'
      setTimeout(function() {
        forEachNodeList(slideItems, function(el) {
          removeClass(el, "tns-moving");
        });
      }, 300);
    }
  }

  // set tabindex & aria-selected on Nav
  function updateNavStatus() {
    // get current nav
    if (nav) {
      navCurrentIndex = navClicked !== -1 ? navClicked : getAbsIndex();
      navClicked = -1;

      if (navCurrentIndex !== navCurrentIndexCached) {
        var navPrev = navItems[navCurrentIndexCached],
          navCurrent = navItems[navCurrentIndex];

        setAttrs(navPrev, {
          tabindex: "-1",
          "aria-selected": "false"
        });
        setAttrs(navCurrent, {
          tabindex: "0",
          "aria-selected": "true"
        });
        removeClass(navPrev, navActiveClass);
        addClass(navCurrent, navActiveClass);
      }
    }
  }

  function getLowerCaseNodeName(el) {
    return el.nodeName.toLowerCase();
  }

  function isButton(el) {
    return getLowerCaseNodeName(el) === "button";
  }

  function isAriaDisabled(el) {
    return el.getAttribute("aria-disabled") === "true";
  }

  function disEnableElement(isButton, el, val) {
    if (isButton) {
      el.disabled = val;
    } else {
      el.setAttribute("aria-disabled", val.toString());
    }
  }

  // set 'disabled' to true on controls when reach the edges
  function updateControlsStatus() {
    if (!controls || rewind || loop) {
      return;
    }

    var prevDisabled = prevIsButton
        ? prevButton.disabled
        : isAriaDisabled(prevButton),
      nextDisabled = nextIsButton
        ? nextButton.disabled
        : isAriaDisabled(nextButton),
      disablePrev = index === indexMin ? true : false,
      disableNext = !rewind && index === indexMax ? true : false;

    if (disablePrev && !prevDisabled) {
      disEnableElement(prevIsButton, prevButton, true);
    }
    if (!disablePrev && prevDisabled) {
      disEnableElement(prevIsButton, prevButton, false);
    }
    if (disableNext && !nextDisabled) {
      disEnableElement(nextIsButton, nextButton, true);
    }
    if (!disableNext && nextDisabled) {
      disEnableElement(nextIsButton, nextButton, false);
    }
  }

  // set duration
  function resetDuration(el, str) {
    if (TRANSITIONDURATION) {
      el.style[TRANSITIONDURATION] = str;
    }
  }

  function getContainerTransformValue() {
    var val;
    if (horizontal) {
      if (fixedWidth) {
        val = -(fixedWidth + gutter) * index + "px";
      } else {
        var denominator = TRANSFORM ? slideCountNew : items;
        val = (-index * 100) / denominator + "%";
      }
    } else {
      val = -slideOffsetTops[index] + "px";
    }
    return val;
  }

  function doContainerTransformSilent(val) {
    resetDuration(container, "0s");
    doContainerTransform(val);
    setTimeout(function() {
      resetDuration(container, "");
    }, 0);
  }

  function doContainerTransform(val, test) {
    if (!val) {
      val = getContainerTransformValue();
    }
    container.style[transformAttr] = transformPrefix + val + transformPostfix;
  }

  function animateSlide(number, classOut, classIn, isOut) {
    var l = number + items;
    if (!loop) {
      l = Math.min(l, slideCountNew);
    }

    for (var i = number; i < l; i++) {
      var item = slideItems[i];

      // set item positions
      if (!isOut) {
        item.style.left = ((i - index) * 100) / items + "%";
      }

      if (animateDelay && TRANSITIONDELAY) {
        item.style[TRANSITIONDELAY] = item.style[ANIMATIONDELAY] =
          (animateDelay * (i - number)) / 1000 + "s";
      }
      removeClass(item, classOut);
      addClass(item, classIn);

      if (isOut) {
        slideItemsOut.push(item);
      }
    }
  }

  // make transfer after click/drag:
  // 1. change 'transform' property for mordern browsers
  // 2. change 'left' property for legacy browsers
  var transformCore = (function() {
    return carousel
      ? function(duration, distance) {
          if (!distance) {
            distance = getContainerTransformValue();
          }

          // constrain the distance when non-loop no-edgePadding fixedWidth reaches the right edge
          if (hasRightDeadZone && index === indexMax) {
            distance =
              -((fixedWidth + gutter) * slideCountNew - vpInner) + "px";
          }

          if (TRANSITIONDURATION || !duration) {
            // for morden browsers with non-zero duration or
            // zero duration for all browsers
            doContainerTransform(distance);
            // run fallback function manually
            // when duration is 0 / container is hidden
            if (!duration || !isVisible(container)) {
              onTransitionEnd();
            }
          } else {
            // for old browser with non-zero duration
            jsTransform(
              container,
              transformAttr,
              transformPrefix,
              transformPostfix,
              distance,
              speed,
              onTransitionEnd
            );
          }

          if (!horizontal) {
            updateContentWrapperHeight();
          }
        }
      : function(duration) {
          slideItemsOut = [];

          var eve = {};
          eve[TRANSITIONEND] = eve[ANIMATIONEND] = onTransitionEnd;
          removeEvents(slideItems[indexCached], eve);
          addEvents(slideItems[index], eve);

          animateSlide(indexCached, animateIn, animateOut, true);
          animateSlide(index, animateNormal, animateIn);

          // run fallback function manually
          // when transition or animation not supported / duration is 0
          if (!TRANSITIONEND || !ANIMATIONEND || !duration) {
            onTransitionEnd();
          }
        };
  })();

  function doTransform(duration, distance) {
    // check duration is defined and is a number
    if (isNaN(duration)) {
      duration = speed;
    }

    // if container is hidden, set duration to 0
    // to fix an issue where browser doesn't fire ontransitionend on hidden element
    if (animating && !isVisible(container)) {
      duration = 0;
    }

    transformCore(duration, distance);
  }

  function render(e, sliderMoved) {
    if (updateIndexBeforeTransform) {
      updateIndex();
    }

    // render when slider was moved (touch or drag) even though index may not change
    if (index !== indexCached || sliderMoved) {
      // events
      events.emit("indexChanged", info());
      events.emit("transitionStart", info());

      // pause autoplay when click or keydown from user
      if (animating && e && ["click", "keydown"].indexOf(e.type) >= 0) {
        stopAutoplay();
      }

      running = true;
      doTransform();
    }
  }

  /*
   * Transfer prefixed properties to the same format
   * CSS: -Webkit-Transform => webkittransform
   * JS: WebkitTransform => webkittransform
   * @param {string} str - property
   *
   */
  function strTrans(str) {
    return str.toLowerCase().replace(/-/g, "");
  }

  // AFTER TRANSFORM
  // Things need to be done after a transfer:
  // 1. check index
  // 2. add classes to visible slide
  // 3. disable controls buttons when reach the first/last slide in non-loop slider
  // 4. update nav status
  // 5. lazyload images
  // 6. update container height
  function onTransitionEnd(event) {
    // check running on gallery mode
    // make sure trantionend/animationend events run only once
    if (carousel || running) {
      events.emit("transitionEnd", info(event));

      if (!carousel && slideItemsOut.length > 0) {
        for (var i = 0; i < slideItemsOut.length; i++) {
          var item = slideItemsOut[i];
          // set item positions
          item.style.left = "";

          if (ANIMATIONDELAY && TRANSITIONDELAY) {
            item.style[ANIMATIONDELAY] = "";
            item.style[TRANSITIONDELAY] = "";
          }
          removeClass(item, animateOut);
          addClass(item, animateNormal);
        }
      }

      /* update slides, nav, controls after checking ...
       * => legacy browsers who don't support 'event' 
       *    have to check event first, otherwise event.target will cause an error 
       * => or 'gallery' mode: 
       *   + event target is slide item
       * => or 'carousel' mode: 
       *   + event target is container, 
       *   + event.property is the same with transform attribute
       */
      if (
        !event ||
        (!carousel && event.target.parentNode === container) ||
        (event.target === container &&
          strTrans(event.propertyName) === strTrans(transformAttr))
      ) {
        if (!updateIndexBeforeTransform) {
          var indexTem = index;
          updateIndex();
          if (index !== indexTem) {
            events.emit("indexChanged", info());

            doContainerTransformSilent();
          }
        }

        if (autoHeight) {
          runAutoHeight();
        }

        if (nested === "inner") {
          events.emit("innerLoaded", info());
        }
        running = false;
        navCurrentIndexCached = navCurrentIndex;
        indexCached = index;
      }
    }
  }

  // # ACTIONS
  function goTo(targetIndex, e) {
    if (freeze) {
      return;
    }

    // prev slideBy
    if (targetIndex === "prev") {
      onControlsClick(e, -1);

      // next slideBy
    } else if (targetIndex === "next") {
      onControlsClick(e, 1);

      // go to exact slide
    } else {
      if (running) {
        onTransitionEnd();
      }

      // } else if (!running) {
      var absIndex = getAbsIndex(),
        indexGap = 0;
      if (absIndex < 0) {
        absIndex += slideCount;
      }

      if (targetIndex === "first") {
        indexGap = -absIndex;
      } else if (targetIndex === "last") {
        indexGap = carousel
          ? slideCount - items - absIndex
          : slideCount - 1 - absIndex;
      } else {
        if (typeof targetIndex !== "number") {
          targetIndex = parseInt(targetIndex);
        }
        if (!isNaN(targetIndex)) {
          var absTargetIndex = getAbsIndex(targetIndex);
          if (absTargetIndex < 0) {
            absTargetIndex += slideCount;
          }
          indexGap = absTargetIndex - absIndex;
        }
      }

      index += indexGap;

      // if index is changed, start rendering
      if (getAbsIndex(index) !== getAbsIndex(indexCached)) {
        render(e);
      }
    }
  }

  // on controls click
  function onControlsClick(e, dir) {
    // if (!running) {
    if (running) {
      onTransitionEnd();
    }

    var passEventObject;

    if (!dir) {
      e = getEvent(e);
      var target = e.target || e.srcElement;

      while (
        target !== controlsContainer &&
        [prevButton, nextButton].indexOf(target) < 0
      ) {
        target = target.parentNode;
      }

      var targetIn = [prevButton, nextButton].indexOf(target);
      if (targetIn >= 0) {
        passEventObject = true;
        dir = targetIn === 0 ? -1 : 1;
      }
    }

    if (rewind) {
      if (index === indexMin && dir === -1) {
        goTo("last", e);
        return;
      } else if (index === indexMax && dir === 1) {
        goTo(0, e);
        return;
      }
    }

    if (dir) {
      index += slideBy * dir;
      // pass e when click control buttons or keydown
      render(passEventObject || (e && e.type === "keydown") ? e : null);
    }
    // }
  }

  // on nav click
  function onNavClick(e) {
    // if (!running) {
    if (running) {
      onTransitionEnd();
    }

    e = getEvent(e);
    var target = e.target || e.srcElement,
      navIndex;

    // find the clicked nav item
    while (target !== navContainer && !hasAttr(target, "data-nav")) {
      target = target.parentNode;
    }
    if (hasAttr(target, "data-nav")) {
      navIndex = navClicked = [].indexOf.call(navItems, target);
      goTo(navIndex + cloneCount, e);
    }
    // }
  }

  // autoplay functions
  function setAutoplayTimer() {
    autoplayTimer = setInterval(function() {
      onControlsClick(null, autoplayDirection);
    }, autoplayTimeout);

    animating = true;
  }

  function stopAutoplayTimer() {
    clearInterval(autoplayTimer);
    animating = false;
  }

  function updateAutoplayButton(action, txt) {
    setAttrs(autoplayButton, { "data-action": action });
    autoplayButton.innerHTML =
      autoplayHtmlStrings[0] + action + autoplayHtmlStrings[1] + txt;
  }

  function startAutoplay() {
    setAutoplayTimer();
    if (autoplayButton) {
      updateAutoplayButton("stop", autoplayText[1]);
    }
  }

  function stopAutoplay() {
    stopAutoplayTimer();
    if (autoplayButton) {
      updateAutoplayButton("start", autoplayText[0]);
    }
  }

  // programaitcally play/pause the slider
  function play() {
    if (autoplay && !animating) {
      startAutoplay();
      autoplayUserPaused = false;
    }
  }
  function pause() {
    if (animating) {
      stopAutoplay();
      autoplayUserPaused = true;
    }
  }

  function toggleAutoplay() {
    if (animating) {
      stopAutoplay();
      autoplayUserPaused = true;
    } else {
      startAutoplay();
      autoplayUserPaused = false;
    }
  }

  function onVisibilityChange() {
    if (doc.hidden) {
      if (animating) {
        stopAutoplayTimer();
        autoplayVisibilityPaused = true;
      }
    } else if (autoplayVisibilityPaused) {
      setAutoplayTimer();
      autoplayVisibilityPaused = false;
    }
  }

  function mouseoverPause() {
    if (animating) {
      stopAutoplayTimer();
      autoplayHoverPaused = true;
    }
  }

  function mouseoutRestart() {
    if (autoplayHoverPaused) {
      setAutoplayTimer();
      autoplayHoverPaused = false;
    }
  }

  // keydown events on document
  function onDocumentKeydown(e) {
    e = getEvent(e);
    switch (e.keyCode) {
      case KEYS.LEFT:
        onControlsClick(e, -1);
        break;
      case KEYS.RIGHT:
        onControlsClick(e, 1);
    }
  }

  // on key control
  function onControlsKeydown(e) {
    e = getEvent(e);
    var code = e.keyCode;

    switch (code) {
      case KEYS.LEFT:
      case KEYS.UP:
      case KEYS.PAGEUP:
        if (!prevButton.disabled) {
          onControlsClick(e, -1);
        }
        break;
      case KEYS.RIGHT:
      case KEYS.DOWN:
      case KEYS.PAGEDOWN:
        if (!nextButton.disabled) {
          onControlsClick(e, 1);
        }
        break;
      case KEYS.HOME:
        goTo(0, e);
        break;
      case KEYS.END:
        goTo(slideCount - 1, e);
        break;
    }
  }

  // set focus
  function setFocus(focus) {
    focus.focus();
  }

  // on key nav
  function onNavKeydown(e) {
    var curElement = doc.activeElement;
    if (!hasAttr(curElement, "data-nav")) {
      return;
    }

    e = getEvent(e);
    var code = e.keyCode,
      navIndex = [].indexOf.call(navItems, curElement),
      len = visibleNavIndexes.length,
      current = visibleNavIndexes.indexOf(navIndex);

    if (options.navContainer) {
      len = slideCount;
      current = navIndex;
    }

    function getNavIndex(num) {
      return options.navContainer ? num : visibleNavIndexes[num];
    }

    switch (code) {
      case KEYS.LEFT:
      case KEYS.PAGEUP:
        if (current > 0) {
          setFocus(navItems[getNavIndex(current - 1)]);
        }
        break;

      case KEYS.UP:
      case KEYS.HOME:
        if (current > 0) {
          setFocus(navItems[getNavIndex(0)]);
        }
        break;

      case KEYS.RIGHT:
      case KEYS.PAGEDOWN:
        if (current < len - 1) {
          setFocus(navItems[getNavIndex(current + 1)]);
        }
        break;

      case KEYS.DOWN:
      case KEYS.END:
        if (current < len - 1) {
          setFocus(navItems[getNavIndex(len - 1)]);
        }
        break;

      // Can't use onNavClick here,
      // Because onNavClick require event.target as nav items
      case KEYS.ENTER:
      case KEYS.SPACE:
        navClicked = navIndex;
        goTo(navIndex + cloneCount, e);
        break;
    }
  }

  // IE10 scroll function
  function ie10Scroll() {
    transformCore(0, container.scrollLeft);
    indexCached = index;
  }

  function getEvent(e) {
    e = e || win.event;
    return isTouchEvent(e) ? e.changedTouches[0] : e;
  }
  function getTarget(e) {
    return e.target || win.event.srcElement;
  }

  function isTouchEvent(e) {
    return e.type.indexOf("touch") >= 0;
  }

  function preventDefaultBehavior(e) {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
  }

  function onPanStart(e) {
    if (running) {
      onTransitionEnd();
    }

    panStart = true;
    caf(rafIndex);
    rafIndex = raf(function() {
      panUpdate(e);
    });

    var $ = getEvent(e);
    events.emit(isTouchEvent(e) ? "touchStart" : "dragStart", info(e));

    if (
      !isTouchEvent(e) &&
      ["img", "a"].indexOf(getLowerCaseNodeName(getTarget(e))) >= 0
    ) {
      preventDefaultBehavior(e);
    }

    lastPosition.x = initPosition.x = parseInt($.clientX);
    lastPosition.y = initPosition.y = parseInt($.clientY);
    translateInit = parseFloat(
      container.style[transformAttr]
        .replace(transformPrefix, "")
        .replace(transformPostfix, "")
    );

    resetDuration(container, "0s");
  }

  function onPanMove(e) {
    if (panStart) {
      var $ = getEvent(e);
      lastPosition.x = parseInt($.clientX);
      lastPosition.y = parseInt($.clientY);
    }
  }

  function panUpdate(e) {
    if (!moveDirectionExpected) {
      panStart = false;
      return;
    }
    caf(rafIndex);
    if (panStart) {
      rafIndex = raf(function() {
        panUpdate(e);
      });
    }

    if (
      moveDirectionExpected === "?" &&
      lastPosition.x !== initPosition.x &&
      lastPosition.y !== initPosition.y
    ) {
      moveDirectionExpected =
        getTouchDirection(
          toDegree(
            lastPosition.y - initPosition.y,
            lastPosition.x - initPosition.x
          ),
          swipeAngle
        ) === options.axis;
    }

    if (moveDirectionExpected) {
      events.emit(isTouchEvent(e) ? "touchMove" : "dragMove", info(e));

      var x = translateInit,
        dist = getDist(lastPosition, initPosition);
      if (!horizontal || fixedWidth) {
        x += dist;
        x += "px";
      } else {
        var percentageX = TRANSFORM
          ? (dist * items * 100) / (vpInner * slideCountNew)
          : (dist * 100) / vpInner;
        x += percentageX;
        x += "%";
      }

      container.style[transformAttr] = transformPrefix + x + transformPostfix;
    }
  }

  function onPanEnd(e) {
    if (swipeAngle) {
      moveDirectionExpected = "?";
    } // reset

    if (panStart) {
      caf(rafIndex);
      resetDuration(container, "");
      panStart = false;

      var $ = getEvent(e);
      lastPosition.x = parseInt($.clientX);
      lastPosition.y = parseInt($.clientY);
      var dist = getDist(lastPosition, initPosition);

      // initPosition = {x:0, y:0}; // reset positions
      // lastPosition = {x:0, y:0}; // reset positions

      if (Math.abs(dist) >= 5) {
        // drag vs click
        if (!isTouchEvent(e)) {
          // prevent "click"
          var target = getTarget(e);
          addEvents(target, {
            click: function preventClick(e) {
              preventDefaultBehavior(e);
              removeEvents(target, { click: preventClick });
            }
          });
        }

        rafIndex = raf(function() {
          events.emit(isTouchEvent(e) ? "touchEnd" : "dragEnd", info(e));

          if (horizontal) {
            var indexMoved = (-dist * items) / vpInner;
            indexMoved =
              dist > 0 ? Math.floor(indexMoved) : Math.ceil(indexMoved);
            index += indexMoved;
          } else {
            var moved = -(translateInit + dist);
            if (moved <= 0) {
              index = indexMin;
            } else if (moved >= slideOffsetTops[slideOffsetTops.length - 1]) {
              index = indexMax;
            } else {
              var i = 0;
              do {
                i++;
                index = dist < 0 ? i + 1 : i;
              } while (i < slideCountNew && moved >= slideOffsetTops[i + 1]);
            }
          }

          render(e, dist);
        });
      }
    }
  }

  // === RESIZE FUNCTIONS === //
  // (slideOffsetTops, index, items) => vertical_conentWrapper.height
  function updateContentWrapperHeight() {
    innerWrapper.style.height =
      slideOffsetTops[index + items] - slideOffsetTops[index] + "px";
  }

  /*
   * get nav item indexes per items
   * add 1 more if the nav items cann't cover all slides
   * [0, 1, 2, 3, 4] / 3 => [0, 3]
   */
  function getVisibleNavIndex() {
    // reset visibleNavIndexes
    visibleNavIndexes = [];

    var absIndexMin = getAbsIndex() % items;
    while (absIndexMin < slideCount) {
      if (carousel && !loop && absIndexMin + items > slideCount) {
        absIndexMin = slideCount - items;
      }
      visibleNavIndexes.push(absIndexMin);
      absIndexMin += items;
    }

    // nav count * items < slide count means
    // some slides can not be displayed only by nav clicking
    if (
      (loop && visibleNavIndexes.length * items < slideCount) ||
      (!loop && visibleNavIndexes[0] > 0)
    ) {
      visibleNavIndexes.unshift(0);
    }
  }

  /*
   * 1. update visible nav items list
   * 2. add "hidden" attributes to previous visible nav items
   * 3. remove "hidden" attrubutes to new visible nav items
   */
  function updateNavVisibility() {
    if (!nav || navAsThumbnails) {
      return;
    }
    getVisibleNavIndex();

    if (visibleNavIndexes !== visibleNavIndexesCached) {
      forEachNodeList(navItems, function(el, i) {
        if (visibleNavIndexes.indexOf(i) < 0) {
          hideElement(el);
        } else {
          showElement(el);
        }
      });

      // cache visible nav indexes
      visibleNavIndexesCached = visibleNavIndexes;
    }
  }

  function info(e) {
    return {
      container: container,
      slideItems: slideItems,
      navContainer: navContainer,
      navItems: navItems,
      controlsContainer: controlsContainer,
      hasControls: hasControls,
      prevButton: prevButton,
      nextButton: nextButton,
      items: items,
      slideBy: slideBy,
      cloneCount: cloneCount,
      slideCount: slideCount,
      slideCountNew: slideCountNew,
      index: index,
      indexCached: indexCached,
      navCurrentIndex: navCurrentIndex,
      navCurrentIndexCached: navCurrentIndexCached,
      visibleNavIndexes: visibleNavIndexes,
      visibleNavIndexesCached: visibleNavIndexesCached,
      sheet: sheet,
      event: e || {}
    };
  }

  return {
    getInfo: info,
    events: events,
    goTo: goTo,
    play: play,
    pause: pause,
    isOn: isOn,
    updateSliderHeight: updateInnerWrapperHeight,
    rebuild: function() {
      return tns(options);
    },

    destroy: function() {
      // remove win event listeners
      removeEvents(win, { resize: onResize });

      // remove arrowKeys eventlistener
      removeEvents(doc, docmentKeydownEvent);

      // sheet
      sheet.disabled = true;

      // cloned items
      if (loop) {
        for (var j = cloneCount; j--; ) {
          if (carousel) {
            slideItems[0].remove();
          }
          slideItems[slideItems.length - 1].remove();
        }
      }

      // Slide Items
      var slideClasses = ["tns-item", slideActiveClass];
      if (!carousel) {
        slideClasses = slideClasses.concat("tns-normal", animateIn);
      }

      for (var i = slideCount; i--; ) {
        var slide = slideItems[i];
        if (slide.id.indexOf(slideId + "-item") >= 0) {
          slide.id = "";
        }

        slideClasses.forEach(function(cl) {
          removeClass(slide, cl);
        });
      }
      removeAttrs(slideItems, ["style", "aria-hidden", "tabindex"]);
      slideItems = slideId = slideCount = slideCountNew = cloneCount = null;

      // controls
      if (controls) {
        removeEvents(controlsContainer, controlsEvents);
        if (options.controlsContainer) {
          removeAttrs(controlsContainer, ["aria-label", "tabindex"]);
          removeAttrs(controlsContainer.children, [
            "aria-controls",
            "aria-disabled",
            "tabindex"
          ]);
        }
        controlsContainer = prevButton = nextButton = null;
      }

      // nav
      if (nav) {
        removeEvents(navContainer, navEvents);
        if (options.navContainer) {
          removeAttrs(navContainer, ["aria-label"]);
          removeAttrs(navItems, ["aria-selected", "aria-controls", "tabindex"]);
        }
        navContainer = navItems = null;
      }

      // auto
      if (autoplay) {
        clearInterval(autoplayTimer);
        if (autoplayButton) {
          removeEvents(autoplayButton, { click: toggleAutoplay });
        }
        removeEvents(container, hoverEvents);
        removeEvents(container, visibilityEvent);
        if (options.autoplayButton) {
          removeAttrs(autoplayButton, ["data-action"]);
        }
      }

      // container
      container.id = containerIdCached || "";
      container.className = container.className.replace(classContainer, "");
      removeElementStyles(container);
      if (carousel && TRANSITIONEND) {
        var eve = {};
        eve[TRANSITIONEND] = onTransitionEnd;
        removeEvents(container, eve);
      }
      removeEvents(container, touchEvents);
      removeEvents(container, dragEvents);

      // outerWrapper
      containerParent.insertBefore(container, outerWrapper);
      outerWrapper.remove();
      outerWrapper = innerWrapper = container = index = indexCached = items = slideBy = navCurrentIndex = navCurrentIndexCached = hasControls = visibleNavIndexes = visibleNavIndexesCached = this.getInfo = this.events = this.goTo = this.play = this.pause = this.destroy = null;
      this.isOn = isOn = false;
    }
  };
};
