<template>
  <div v-if="displayMessage" class="banner_outer" data-aos="fade-up" data-aos-delay="300">
    <div class="banner_inner">
      <Logo type="large" class="small_logo" />
      <div class="banner_content">
        <h2 class="heading heading--3">{{ heading }}</h2>
        <div v-html="copy"></div>
      </div>
    </div>

    <button class="close_btn" @click="displayMessage = false">
      <svg class="close_icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
          fill="currentColor"
          d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  name: "Banner",
  components: {
    Logo
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    copy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      displayMessage: true
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.banner_outer {
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 2px solid RGB(41, 153, 129);
  background: #fff;
  z-index: 100;
  width: 100%;
  box-shadow: 0px -2px 10px 0px rgba(179, 179, 179, 1);
  .banner_inner {
    margin: 0 auto;
    padding: 32px 16px 24px 16px;
    max-width: 1168px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    @include respond-to("s") {
      flex-direction: row;
      align-items: center;
    }
  }
  .small_logo {
    width: 152px;
    height: 79px;
    margin: 0 auto 16px auto;
    .logo--large {
      width: 152px;
      height: 79px;
    }
  }
  .banner_heading {
    font-size: 32px;
  }
  .banner_content {
    width: 100%;
    text-align: center;
    @include respond-to("s") {
      text-align: left;
      margin-left: 64px;
      width: calc(100% - 152px - 64px);
    }
  }
  .close_btn {
    cursor: pointer;
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    position: fixed;
    top: 24px;
    right: 24px;
    .close_icon {
      height: 100%;
      color: RGB(41, 153, 129);
    }
  }
}
</style>
