<template>
  <transition name="fade">
    <div>
      <Hero alt="forum" heading="OBA Forum" /><br />
      <br />
      <Gallery
        :type="6"
        :slides="gallery"
        heading="OBA Forum"
        info="Voor presentaties bieden wij u nu ook onze nieuwste aanwinst: OBA Forum. Dit is een hoge ruimte met een uitschuifbare tribune en een balkon, die beide perfect zicht bieden op het grote scherm. De tribune heeft een capaciteit van 57 personen die met stoelen kan worden aangevuld tot 90 personen.<br><br>OBA Forum wordt door een geluidsdichte, glazen wand gescheiden van het gebouw. In combinatie met de grote raampartijen zorgt dit voor daglicht in overvloed. Als uw event een donkere ruimte nodig heeft, is het uiteraard mogelijk de gordijnen te sluiten.<br><br>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed url="https://my.matterport.com/show/?m=woPwxWoL6d3" bg="forum" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Forum",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Forum-1" },
        { delay: 100, bg: "Forum-2" },
        { delay: 200, bg: "Forum-3" },
        { delay: 300, bg: "Forum-4" },
        { delay: 400, bg: "Forum-5" },
        { delay: 500, bg: "Forum-6" }
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(space => space.url.name === this.$options.name);
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
