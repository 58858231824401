<template>
  <nav class="navigation">
    <router-link :to="{ name: 'HomeOverview' }" class="navigation__link">Home</router-link>
    <div class="navigation__dropdown">
      <router-link :to="{ name: 'OnzeRuimtes' }" class="navigation__link navigation__link--head"
        >Onze ruimtes</router-link
      >
      <router-link :to="{ name: 'VergaderzalenOverview' }" class="navigation__link navigation__link--sub"
        >Vergaderzalen</router-link
      >
      <router-link :to="{ name: 'Forum' }" class="navigation__link navigation__link--sub">Forum</router-link>
      <router-link :to="{ name: 'Theater' }" class="navigation__link navigation__link--sub">Theater</router-link>
      <router-link :to="{ name: 'PrivateDining' }" class="navigation__link navigation__link--sub"
        >Private Dining</router-link
      >
    </div>
    <div class="navigation__dropdown">
      <router-link :to="{ name: 'UwEvent' }" class="navigation__link navigation__link--head">Uw event</router-link>
      <router-link :to="{ name: 'Vergaderen' }" class="navigation__link navigation__link--sub">Vergaderen</router-link>
      <router-link :to="{ name: 'CongresBeurs' }" class="navigation__link navigation__link--sub"
        >Congres & beurs</router-link
      >
      <router-link :to="{ name: 'Presenteren' }" class="navigation__link navigation__link--sub"
        >Presenteren</router-link
      >
      <router-link :to="{ name: 'EtenDrinken' }" class="navigation__link navigation__link--sub"
        >Eten & drinken</router-link
      >
      <router-link :to="{ name: 'Activiteiten' }" class="navigation__link navigation__link--sub"
        >Activiteiten</router-link
      >
    </div>
    <router-link :to="{ name: 'Contact' }" class="navigation__link">Contact</router-link>
  </nav>
</template>

<script>
import { Events } from "@/events";

export default {
  name: "Navigation",

  mounted() {
    Events.$on("toggle-navigation", () => {
      this.toggleNavigation();
    });
  },

  methods: {
    toggleNavigation() {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      });

      this.$el.classList.toggle("active");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

// navigation
.navigation {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  top: 60px;
  min-height: 0;
  max-height: 0;
  padding: 0 16px;
  border-bottom: 1px solid $red;
  background: $white;
  will-change: contents;
  transition: all $animated;
  z-index: 99;

  &__dropdown {
    display: flex;
    flex-direction: column;
  }

  &__link {
    display: inline-flex;
    line-height: 32px;
    margin-bottom: 16px;
    opacity: 0;
    color: $black;
    transition: opacity $animated;

    &:last-of-type {
      margin-bottom: 0;
    }

    &--head {
      @include font-book;
      margin-bottom: 0;
      color: $warm-grey;
    }

    &--sub {
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 16px;
      }
    }
  }

  // navigation's active state
  &.active {
    min-height: calc(100vh - 60px);
    padding: 24px 16px;
    border-bottom: 1px solid $red;
    border-bottom: 4px solid $red;
    transition: all $animated;

    .navigation__link {
      opacity: 1;
    }
  }

  // media queries
  @include respond-to("s") {
    display: none;
  }

  // landscape media query
  @media screen and (orientation: landscape) {
    max-height: 0;

    &.active {
      max-height: initial;
    }
  }
}
</style>
