<template>
  <section class="form">
    <h2 class="form__heading heading heading--2" data-aos="fade-up">contact</h2>
    <div class="form__info" data-aos="fade-up">
      <div class="form__info-column">
        <p>
          Heeft u behoefte aan meer informatie of wilt u een vrijblijvende offerte ontvangen voor uw zakelijke
          evenement? Aarzel niet om contact met ons op te nemen. We doen ons best u vanaf het eerste moment alle zorg
          uit handen te nemen en streven ernaar u binnen 24 uur een offerte op maat te sturen.
        </p>
      </div>
      <div class="form__info-column">
        <p>
          Het team van OBA Congres vindt u op de zesde etage en is dagelijks tijdens kantooruren bereikbaar op
          telefoonnummer <a href="tel:0205230910">020-523 09 10</a> of per mail via
          <a href="mailto:congres@oba.nl">congres@oba.nl</a>.
        </p>
      </div>
    </div>

    <section v-if="formHasBeenSent && !formSuccess" class="form-missing-required-fields">
      Vergeet a.u.b. niet de verplichte velden in te vullen.
    </section>

    <section v-if="formHasBeenSent && formSuccess" class="form-sent-feedback" data-aos="fade-up">
      Uw aanvraag is succesvol verstuurd, wij nemen zo spoedig mogelijk contact op.
    </section>

    <form v-if="!formSuccess" method="post" class="form__container" @submit.prevent="checkForm()">
      <div class="form__left" data-aos="fade-up">
        <fieldset
          v-for="(item, index) in formLeft"
          :key="`form-filed--${index}`"
          :ref="`${item.handel}Fieldset`"
          :class="[
            form[item.handel].error || (item.type === 'email' && errorMatchingEmails) ? 'error' : 'valid',
            `col-${item.width}`
          ]"
        >
          <legend>{{ item.required ? item.text + " *" : item.text }}</legend>
          <input
            v-if="item.type !== 'textarea'"
            :ref="`${item.handel}`"
            v-model="form[item.handel].value"
            :type="item.type"
            :name="item.handel"
            :placeholder="item.placeholder"
            @keyup="checkField(item.handel)"
          />
          <textarea
            v-else
            :ref="`${item.handel}`"
            v-model="form[item.handel].value"
            cols="30"
            rows="10"
            :name="item.handel"
            :placeholder="item.placeholder"
            @keyup="checkField(item.handel)"
          />
        </fieldset>

        <div class="form__footnote for-two-column-layout">*verplichte velden</div>
      </div>

      <div class="form__right" data-aos="fade-up">
        <fieldset
          v-for="(item, index) in formRight"
          :key="`form-filed--${index}`"
          :ref="`${item.handel}Fieldset`"
          :class="[
            form[item.handel].error || (item.type === 'email' && errorMatchingEmails) ? 'error' : 'valid',
            `col-${item.width}`
          ]"
        >
          <legend>{{ item.required ? item.text + " *" : item.text }}</legend>
          <input
            v-if="item.type !== 'textarea'"
            :ref="`${item.handel}`"
            v-model="form[item.handel].value"
            :type="item.type"
            :name="item.handel"
            :placeholder="item.placeholder"
            @keyup="checkField(item.handel)"
          />
          <textarea
            v-else
            :ref="`${item.handel}`"
            v-model="form[item.handel].value"
            cols="30"
            rows="10"
            :name="item.handel"
            :placeholder="item.placeholder"
            @keyup="checkField(item.handel)"
          />
        </fieldset>

        <div class="form__footnote for-one-column-layout">*verplichte velden</div>

        <button type="submit" class="form__submit">verstuur</button>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "Form",

  data() {
    return {
      form: {
        name: {
          width: 2,
          text: "naam/organisatie",
          type: "text",
          value: null,
          handel: "name",
          required: true,
          error: "",
          error_message: "Naam is verplicht"
        },
        address: {
          width: 1,
          text: "(factuur)adres",
          type: "text",
          value: null,
          handel: "address",
          required: false,
          error: "",
          error_message: "Het adres moet minstens vier karakters lang zijn"
        },
        number: {
          width: 1,
          text: "nummer",
          type: "text",
          value: null,
          handel: "number",
          required: false,
          error: "",
          error_message: "Het nummer moet minstens een karakters lang zijn"
        },
        post: {
          width: 1,
          text: "postcode",
          type: "text",
          value: null,
          handel: "post",
          required: false,
          error: "",
          error_message: "De postcode moet minstens zes karakters lang zijn"
        },
        place: {
          width: 1,
          text: "plaats",
          type: "text",
          value: null,
          handel: "place",
          required: false,
          error: "",
          error_message: "De plaats moet minstens drie karakters lang zijn"
        },
        email: {
          width: 2,
          text: "e-mail",
          type: "email",
          value: null,
          handel: "email",
          required: true,
          error: "",
          error_message: "E-mail is verplicht"
        },
        email2: {
          width: 2,
          text: "e-mail controle",
          type: "email",
          value: null,
          handel: "email2",
          required: true,
          error: "",
          error_message: "E-mail Controle is verplicht"
        },
        phone: {
          width: 2,
          text: "telefoonnummer",
          type: "text",
          value: null,
          handel: "phone",
          required: false,
          error: "",
          error_message: "Telefoon moet minimaal 7 karakters lang zijn"
        },
        message: {
          width: 2,
          text: "bericht",
          type: "textarea",
          value: null,
          handel: "message",
          required: false,
          error: "",
          error_message: "Het bericht moet minstens tien karakters lang zijn",
          placeholder:
            "Bijvoorbeeld: terugbelverzoek, aantal personen, gelegenheid, wensen met betrekking tot de opstelling, etc."
        }
      },
      formSuccess: false,
      formHasBeenSent: false
    };
  },

  computed: {
    errorMatchingEmails() {
      return this.form.email.value && this.form.email.value && this.form.email.value !== this.form.email2.value;
    },
    blockFormSubmit() {
      const keyArray = Object.keys(this.form);
      return (
        (keyArray.filter(e => {
          return this.form[e].error.length > 0;
        }).length > 0 || this.errorMatchingEmails) === true
      );
    },
    formLeft() {
      const content = [];
      const allowKeys = ["name", "address", "number", "number", "post", "place", "email", "email2", "phone"];
      Object.keys(this.form).filter(e => {
        return allowKeys.includes(e) ? content.push(this.form[e]) : null;
      });
      return content;
    },
    formRight() {
      const content = [];
      const allowKeys = ["message"];
      Object.keys(this.form).filter(e => {
        return allowKeys.includes(e) ? content.push(this.form[e]) : null;
      });
      return content;
    }
  },
  methods: {
    checkForm() {
      const keyArray = Object.keys(this.form);
      keyArray.forEach(i => {
        this.checkField(i);
      });
      this.sendForm();
    },
    checkField(key) {
      this.form[key].error =
        (this.form[key].type === "email" ? this.checkIsValidEmail(key) : this.checkIsValid(key)) === true
          ? ""
          : this.form[key].error_message;
    },
    checkIsValid(key) {
      return this.form[key].required ? this.form[key].value && this.form[key].value.length > 0 : true;
    },
    checkIsValidEmail(key) {
      // eslint-disable-next-line
      if(this.form[key] && this.form[key].value){
        const value = this.form[key].value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }
      return false;
    },

    sendForm() {
      this.formHasBeenSent = true;
      if (this.blockFormSubmit) {
        this.formSuccess = false;
      } else {
        this.formSuccess = true;
        axios
          .post("/send-email", {
            name: this.form.name.value,
            address: this.form.address.value,
            number: this.form.number.value,
            post: this.form.post.value,
            place: this.form.place.value,
            email: this.form.email.value,
            phone: this.form.phone.value,
            message: this.form.message.value
          })
          .then(response => {
            // eslint-disable-next-line
            this.formHasBeenSent = true;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.form {
  margin: 0 auto 80px;
  padding: 0 16px;
  max-width: $inner-max-width;

  fieldset {
    position: relative;
    width: calc(100% - 4px);
    margin-bottom: 20px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid $warm-grey;
    transition: all $animated;
    &.col-1 {
      grid-column: auto / span 1;
    }
    &.col-2 {
      grid-column: auto / span 2;
    }

    &.error {
      border: 1px solid $red;
      color: $red;
    }

    &.valid {
      animation: validate 2s ease-in-out forwards;
    }
  }

  input,
  textarea {
    width: 100%;
    height: 40px;
    padding-left: 12px;
    background: transparent;
    border: none;
    border-radius: 5px;
    resize: none;

    &:focus {
      outline: none;
    }
  }
  textarea {
    height: 100%;
    flex: 1;
  }

  legend {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    background: white;
    padding: 0 4px;
    z-index: 99;
  }

  @include respond-to("l") {
    padding: 0;
  }
}

.form__heading {
  margin-bottom: 16px;
}

.form__info {
  margin-bottom: 20px;
  p {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.form__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form__left,
.form__right {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  @include respond-to("s") {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
}

.form__right {
  grid-template-rows: 1fr;
}

.form__submit {
  grid-column: auto / span 2;
  align-self: flex-start;
  width: 100%;
  margin: 0;
  padding-right: 32px;
  border: none;
  text-align: left;
  @include font-black;
  color: $red;
  background: transparent;
  cursor: pointer;
  transition: transform $animated-short;
  @include respond-to("s") {
    text-align: right;
  }

  &:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 10px;
    margin: 3px 0 0 12px;
    background-image: url("../assets/images/red-arrow-right.svg");
    background-size: cover;
    transition: transform $animated;
  }

  &:hover {
    &:after {
      transform: translateX(8px);
    }
  }
}

@include respond-to("s") {
  .form__info {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }

  .form__info-column {
    width: calc(50% - 16px);
  }

  .form__container {
    flex-direction: row;
  }

  .form__left {
    padding-right: 16px;
  }

  .form__right {
    padding-left: 16px;
  }

  .form__submit {
    align-self: flex-end;
    margin-right: 16px;
  }
}

.form__footnote {
  padding-left: 0.75em;
  &.for-one-column-layout {
    margin-bottom: 12px;
    @include respond-to("s") {
      display: none;
    }
  }
  &.for-two-column-layout {
    display: none;
    @include respond-to("s") {
      display: block;
    }
  }
}

.form-sent-feedback,
.form-missing-required-fields {
  font-family: "Avenir-Black", sans-serif;
  font-weight: normal;
}

.form-missing-required-fields {
  color: $red;
  margin-bottom: 24px;
}

@keyframes validate {
  0% {
    border: 1px solid $black;
    color: $black;
  }

  50% {
    border: 1px solid $green;
  }
}
</style>
