<template>
  <transition name="fade">
    <div>
      <Hero alt="Theater" heading="OBA Theater" />
      <Gallery
        :type="6"
        :slides="gallery"
        heading="OBA Theater"
        info="Op de zevende etage van OBA Oosterdok vindt u het OBA Theater, dat met een capaciteit van 260 zitplaatsen geschikt is voor grotere presentaties en voorstellingen. Het podium van 180 vierkante meter is voorzien van alle professionele faciliteiten, inclusief een backstageruimte met monitoren.<br/><br/>
        Daarnaast is de ruimte uitermate geschikt om te huren in combinatie met een livestream en/of opnames. Benieuwd naar de mogelijkheden en de extra kosten? Neem dan <a href='#contact'>contact</a> met ons op. We helpen u graag.<br/><br/>
        Grenzend aan het OBA Theater ligt een foyer, eventueel met garderobe. Gasten kunnen hier worden ontvangen met koffie, in de pauze van een snack genieten of ter afsluiting borrelen.<br/><br/>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed url="https://my.matterport.com/show/?m=SPt85Ay9hQp" bg="theater" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Theater",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Theater-1" },
        { delay: 100, bg: "Theater-2" },
        { delay: 200, bg: "Theater-3" },
        { delay: 300, bg: "Theater-4" },
        { delay: 400, bg: "Theater-5" },
        { delay: 500, bg: "Theater-6" }
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(space => space.url.name === this.$options.name);
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
