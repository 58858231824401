<template>
  <transition name="fade">
    <div>
      <Hero alt="Hall-3" heading="6.3 Singelzaal" />
      <Gallery
        :type="6"
        :slides="gallery"
        heading="6.3 Singelzaal"
        info="De Singelzaal leent zich uitstekend voor vergaderingen en presentaties. De ruimte is ingericht met comfortabel designmeubilair en in te delen in diverse opstellingen, afgestemd op uw behoefte. De zaal is van alle gemakken voorzien om een optimale presentatie te geven.<br><br>Behoefte aan meer ruimte? De Singelzaal kan worden samengevoegd met de <a href='/vergaderzalen/6-4-herenzaal'>Herenzaal</a> en is zo de ideale ruimte voor grotere vergaderingen of presentaties.<br><br>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed
        heading="3D/360º Walkthroughs"
        info="Om een betere indruk te krijgen van onze aanbod kunt u een virtuele 3D tour doen. Bekijk de zaal in 360º of van bovenaf als plattegrond."
        url="https://my.matterport.com/show/?m=QbrwbiQ3EgG"
        combi-url="https://my.matterport.com/show/?m=wfZM82CQRUH"
        bg="hall-3"
      />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Hall3",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Hall-3-1" },
        { delay: 100, bg: "Hall-3-2" },
        { delay: 200, bg: "Hall-3-3" },
        { delay: 300, bg: "Hall-3-4" },
        { delay: 400, bg: "Hall-3-5" },
        { delay: 500, bg: "Hall-3-6" }
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(
        space => space.url.name === this.$options.name || space.url.name.replace("-", "") === this.$options.name
      );
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
