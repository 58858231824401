// Created some variables so prcies only need be be changed here if they're used mutiple times
const mediumSpacePrices = [
  "€ 400,00 <br/>Tarief 1 dagdeel Excl. btw",
  "€ 550,00 <br/>Tarief 2 dagdelen Excl. btw",
  "€ 850,00 <br/>Tarief 3 dagdelen Excl. btw"
];

const combinedSpacePrices = [
  "€ 800,00 <br/>Tarief 1 dagdeel Excl. btw",
  "€ 1100,00 <br/>Tarief 2 dagdelen Excl. btw",
  "€ 1700,00 <br/>Tarief 3 dagdelen Excl. btw"
];

const spacesInfo = [
  {
    name: "OBA Forum",
    url: { name: "Forum" },
    area: "85",
    persons: "90",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "microfoon"],
    size: "large",
    prices: [
      "€ 1000,00 <br/>Tarief 1 dagdeel Excl. btw",
      "€ 1500,00 <br/>Tarief 2 dagdelen Excl. btw",
      "€ 2250,00 <br/>Tarief 3 dagdelen Excl. btw"
    ]
  },
  {
    name: "OBA Theater",
    url: { name: "Theater" },
    area: "180",
    persons: "260",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "microfoon", "livestream en/of opname"],
    size: "large",
    prices: [
      "€ 1500,00 <br/>Tarief 1 dagdeel Excl. btw",
      "€ 2500,00 <br/>Tarief 2 dagdelen Excl. btw",
      "€ 3700,00 <br/>Tarief 3 dagdelen Excl. btw"
    ]
  },
  {
    url: { name: "Hall-1" },
    name: "6.1 vergaderzaal",
    area: "35",
    persons: "12-16",
    options: ["tv-scherm", "whiteboard", "flipover", "audio video conference set"],
    size: "medium",
    prices: [
      "€ 300,00 <br/>Tarief 1 dagdeel Excl. btw",
      "€ 475,00 <br/>Tarief 2 dagdelen Excl. btw",
      "€ 625,00 <br/>Tarief 3 dagdelen Excl. btw"
    ]
  },
  {
    url: { name: "Hall-2" },
    name: "6.2 vergaderzaal",
    area: "28",
    persons: "2-8",
    options: ["tv-scherm", "whiteboard", "flipover"],
    size: "medium",
    prices: [
      "€ 200,00 <br/>Tarief 1 dagdeel Excl. btw",
      "€ 275,00 <br/>Tarief 2 dagdelen Excl. btw",
      "€ 425,00 <br/>Tarief 3 dagdelen Excl. btw"
    ]
  },
  {
    url: { name: "Hall-3" },
    name: "6.3 Singelzaal",
    area: "58",
    persons: "16-30",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "audio video conference set"],
    size: "medium",
    prices: mediumSpacePrices
  },
  {
    url: { name: "Hall-4" },
    name: "6.4 Herenzaal",
    area: "58",
    persons: "16-30",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "audio video conference set"],
    size: "medium",
    prices: mediumSpacePrices
  },
  {
    url: { name: "Hall-5" },
    name: "6.5 Keizerzaal",
    area: "58",
    persons: "16-30",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "audio video conference set"],
    size: "medium",
    prices: mediumSpacePrices
  },
  {
    url: { name: "Hall-6" },
    name: "6.6 Prinsenzaal",
    area: "81",
    persons: "16-40",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "audio video conference set"],
    size: "medium",
    prices: mediumSpacePrices
  },
  {
    name: "Prinsen- & Keizerzaal",
    area: "120",
    persons: "44-70",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "microfoon", "audio video conference set"],
    size: "medium",
    prices: combinedSpacePrices
  },
  {
    name: "Heren- & Singelzaal",
    area: "120",
    persons: "40-60",
    options: ["beamer", "whiteboard", "flipover", "geluidsinstallatie", "microfoon", "audio video conference set"],
    size: "medium",
    prices: combinedSpacePrices
  }
];

const meetingSpacesSlides = [
  {
    ...spacesInfo.find(space => space.url.name === "Hall-1"),
    showArea: true,
    delay: 0,
    bg: 5
  },
  {
    ...spacesInfo.find(space => space.url.name === "Hall-2"),
    showArea: true,
    delay: 100,
    bg: 6
  },
  {
    ...spacesInfo.find(space => space.url.name === "Hall-3"),
    showArea: true,
    delay: 200,
    bg: 7
  },
  {
    ...spacesInfo.find(space => space.url.name === "Hall-4"),
    showArea: true,
    delay: 300,
    bg: 8
  },
  {
    ...spacesInfo.find(space => space.url.name === "Hall-5"),
    showArea: true,
    delay: 400,
    bg: 9
  },
  {
    ...spacesInfo.find(space => space.url.name === "Hall-6"),
    showArea: true,
    delay: 500,
    bg: 10
  }
];

const otherSpacesSlides = [
  {
    ...spacesInfo.find(space => space.url.name === "Forum"),
    showArea: false,
    delay: 0,
    bg: 11
  },
  {
    ...spacesInfo.find(space => space.url.name === "Theater"),
    showArea: false,
    delay: 100,
    bg: 12
  },
  {
    url: { name: "PrivateDining" },
    name: "private dining",
    showArea: false,
    delay: 200,
    bg: 13
  }
];

const spacesOverviewSlides = [
  {
    url: { name: "VergaderzalenOverview" },
    name: "vergaderzalen",
    delay: 0,
    bg: 1
  },
  {
    ...spacesInfo.find(space => space.url.name === "Forum"),
    showArea: false,
    delay: 100,
    bg: 2
  },
  {
    ...spacesInfo.find(space => space.url.name === "Theater"),
    showArea: false,
    delay: 200,
    bg: 12
  },
  {
    url: { name: "PrivateDining" },
    name: "private dining",
    delay: 300,
    bg: 4
  }
];

export { spacesInfo, meetingSpacesSlides, otherSpacesSlides, spacesOverviewSlides };
