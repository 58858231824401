<template>
  <div>
    <Hero alt="contact" heading="404" />
    <Info
      margin="80px auto"
      heading="Pagina niet gevonden"
      copy1="De pagina die u zoekt bestaat niet, is mogelijk verwijderd, de naam ervan is gewijzigd of hij is tijdelijk niet beschikbaar."
      :links="[
        { name: 'Naar Home', url: { name: 'HomeOverview' } },
        { name: 'Naar Contact', url: { name: 'Contact' } }
      ]"
      logo="true"
    />
    <Footer />
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Info from "@/components/Info.vue";
import Footer from "@/components/Footer.vue";

import AOS from "aos";

export default {
  name: "Error404",
  components: {
    Hero,
    Info,
    Footer
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
